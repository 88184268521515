import { FunctionalResult } from '../../sysObjects/FunctionalResult';
import {
  fetchAction,
  createAction,
  updateAction,
} from '../common/APIHelper';
import settings from '../../config/services.json';
import { getHostConfigAsync } from '../common/HostConfigActions'; 
import ServiceDefinition_Types from '../../sysObjects/apiModels/ServiceDefinition.types';
import Enumerations from '../common/CommonHelpers';
import { KeyValuePair } from '../../sysObjects/common.types';
import System_Types from '../../sysObjects/apiModels/System.types';

namespace ServiceDefinitionActions {
  /**
   * Gets the single item to display.
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo  to pass to the calling method
   * @param {string} id  to pass to the calling method
   * @returns The result of the action
   */
  export const getAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    id: string
  ): Promise<FunctionalResult<ServiceDefinition_Types.ServiceDefinition>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.ServiceDefinitions.get.path!}/${id}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  };

  /**
   * Saves the Service Definition object.
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo  to pass to the calling method
   * @param {ServiceDefinition_Types.serviceDefinition} The record to save.
   * @param {string} id  to pass to the calling method. if not provided, a new record will be created.
   * @returns The result of the action
   */
  export const saveAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    obj: ServiceDefinition_Types.ServiceDefinition,
    id?: string
  ): Promise<FunctionalResult<string>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (!id) {
      return createAction({
        hostPath: host!.result!.path,
        apiPath: settings.ServiceDefinitions.create.path!,
        userId: headerInfo.result!.userId,
        formData: obj,
        accessToken: headerInfo.result!.token,
      });
    }
    return updateAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.ServiceDefinitions.update.path!}/${id}`,
      userId: headerInfo.result!.userId,
      formData: obj,
      accessToken: headerInfo.result!.token,
    });
  };

  /**
   * Gets a list of Service Definitions.
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo  to pass to the calling method
   * @returns The result of the action
   */
  export const findAsync = async (
    query: ServiceDefinition_Types.FindQuery,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<
    FunctionalResult<ServiceDefinition_Types.RetrievedServiceDefinition[]>
  > => {
    let queryParams = [];

    if (query.active != null) {
      queryParams.push(
        `${settings.ServiceDefinitions.find.queryServiceStatus}=${query.active}`
      );
    }

    if (query.relatedConditions && query.relatedConditions.length > 0) {
      query.relatedConditions.forEach((condition) => {
        queryParams.push(
          `${settings.ServiceDefinitions.find.queryRelatedConditions}=${condition}`
        );
      });
    }

    if (query.deliveryFormats && query.deliveryFormats.length > 0) {
      query.deliveryFormats.forEach((format) => {
        queryParams.push(
          `${settings.ServiceDefinitions.find.queryDeliveryFormats}=${format}`
        );
      });
    }

    // Join all query parameters with '&' if there are any
    let queryPath = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.ServiceDefinitions.find.path!}${queryPath}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  };

  /**
   * Gets a list of Service Definitions with prices.
   * @param {string} organisationId the organisation to retrieve the service definitions for.
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo to pass to the calling method.
   * @returns The result of the action.
   */
  export const findWithPricesAsync = async (
    organisationId: string,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<
    FunctionalResult<ServiceDefinition_Types.serviceDefinitionItemWithPrice[]>
  > => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.ServiceDefinitions.findWithPrices.path!}?${
        settings.ServiceDefinitions.findWithPrices.queryOrganisationId
      }=${organisationId}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  };

  /**
   * Creates a default Service Definition object.
   * @returns EMpty Service Definition object
   */
  export const createDefault =
    (): ServiceDefinition_Types.ServiceDefinition => {
      return {
        serviceType: '',
        description: '',
        externalDisplayName: '',
        deliveryFormat: undefined,
        relatedCondition: undefined,
        otherRelatedCondition: '',
        nominalCode: '',
        serviceCode: '',
        status: 1,
      };
    };

  /**
   * Retrieves a collection of KeyValue pairs for the service definitions.
   * The values are formatted as 'serviceType - relatedCondition - deliveryFormat'.
   * @param userClaims The user claims.
   * @param account The account information.
   * @returns A collection of KeyValue pairs for the service definitions.
   */
  export const fetchServiceDefinitions = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    language: string
  ): Promise<FunctionalResult<KeyValuePair<string>[]>> => {
    try {
      const formats = Enumerations.getDeliveryFormats(language);
      const conditions = Enumerations.getRelatedConditions(language);

      const serviceDefinitionResult = await ServiceDefinitionActions.findAsync(
        { active: 0 },
        headerInfo
      );
      if (
        serviceDefinitionResult.isFailure ||
        !serviceDefinitionResult.result
      ) {
        return FunctionalResult.Error('Failed to load service definitions');
      }

      if (
        serviceDefinitionResult.result.some(
          (item) =>
            !formats?.some((format) => format.key === item.deliveryFormat) &&
            !conditions?.some(
              (condition) => condition.key === item.relatedCondition
            )
        )
      ) {
        return FunctionalResult.Error('Failed to load service definitions');
      }

      const items = serviceDefinitionResult.result.map((item) =>
        ServiceDefinitionActions.retrieveServiceDefinitionDescriptorAsKeyValuePair(
          item,
          formats!,
          conditions!
        )
      );

      return FunctionalResult.Success(items);
    } catch (error) {
      return FunctionalResult.Error('Failed to load service definitions');
    }
  };

  /**
   * Retrieves the service definition descriptor.
   * @param serviceDefinition - The service definition item.
   * @param deliveryFormatMap - The delivery format map.
   * @returns The service definition descriptor.
   */
  export const retrieveServiceDefinitionDescriptorAsKeyValuePair = (
    serviceDefinition: ServiceDefinition_Types.RetrievedServiceDefinition,
    deliveryFormatMap: KeyValuePair<number>[],
    relatedConditionMap: KeyValuePair<number>[]
  ): KeyValuePair<string> => {
    return {
      key: serviceDefinition.id,
      value: retrieveServiceDefinitionDescriptor(serviceDefinition, deliveryFormatMap, relatedConditionMap),
    };
  };

  export const retrieveServiceDefinitionDescriptor = (
    serviceDefinition: ServiceDefinition_Types.RetrievedServiceDefinition,
    deliveryFormatMap: KeyValuePair<number>[],
    relatedConditionMap: KeyValuePair<number>[]
  ): string => {
    let format = deliveryFormatMap.findIndex(
      (x) => x.key === serviceDefinition.deliveryFormat
    );
    let relatedCondition = relatedConditionMap.findIndex(
      (x) => x.key === serviceDefinition.relatedCondition
    );

    return `${serviceDefinition.serviceType} - ${relatedConditionMap[relatedCondition].value} - ${deliveryFormatMap[format].value}`;
  };


  /**
   * Asynchronously retrieves service categories.
   *
   * @param headerInfo - The header information containing API header details.
   * @returns A promise that resolves to a FunctionalResult containing an array of service category strings.
   * If the host configuration fails to load, it returns an error FunctionalResult with a message.
   */
  export const retrieveServiceCategoriesAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<string[]>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.ServiceDefinitions.categories.path!}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  };
}

export default ServiceDefinitionActions;
