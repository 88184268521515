import React from 'react';
import StatusBlock from '../../../../../sharedControls/reusableBlocks/statusBlock/StatusBlock';
import { ControlState } from '../../../../../../sysObjects/common.types';
import CancelCaseTypes from './CancelCase.types';
import ButtonBox from '../../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';
import './CancelCase.css';

const CancelCase: React.FC<CancelCaseTypes.IProps> = (props) => {
  const [reasonState, setReasonState] = React.useState<ControlState>('neutral');
  const [reasonNoteState, setReasonNoteState] =
    React.useState<ControlState>('neutral');
  const [reasonNote, setReasonNote] = React.useState<string>('');
  const [reason, setReason] = React.useState<number | undefined>(undefined);
  const [disableDefault, setDisableDefault] = React.useState<boolean>(false);

  const handleReasonChange = (e:  React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === '') {
      setReasonState('negative');
      return;
    }
    setReasonState('positive');
    setDisableDefault(true);
    setReason(parseInt(e.target.value));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    if (e.target.value === '') {
      setReasonNoteState('neutral');
      return;
    }
    setReasonNote(e.target.value);
    setReasonNoteState('positive');
  };

  return (
    <div className="Cancel-Case-Block">
      <div className="Cancel-Case-Details">
        <label htmlFor="Reason-Selection" className="Text-Strong">
          {props.labels.reasonSelectionLabel}
        </label>
        <select
          className="form-select Text-Understated"
          id="Reason-Selection"
          name="Reason-Selection"
          data-testid="Reason-Selection"
          onChange={handleReasonChange}
          onBlur={handleReasonChange}
        >
          <option
            value=""
            disabled={disableDefault}
            className="Text-Understated"
          >
            {props.labels.defaultReasonSelection}
          </option>
          {props.reasons.map((reason) => {
            return (
              <option
                key={reason.key}
                value={reason.key}
                className="Text-Understated"
              >
                {reason.value}
              </option>
            );
          })}
        </select>
        <StatusBlock
          boxSize="medium"
          boxState={reasonState}
          id="Reason-Selection-State"
          showIcon={true}
        />
        <label htmlFor="Reason-Notes" className="Text-Strong">
          {props.labels.reasonNotesLabel}
        </label>
        <textarea
          className="form-textBox Text-Understated"
          id="Reason-Notes"
          data-testid="Reason-Notes"
          name="Reason-Notes"
          rows={4}
          onChange={handleInputChange}
          maxLength={200}
        />
        <StatusBlock
          boxSize="medium"
          boxState={reasonNoteState}
          id="Reason-Notes-State"
          showIcon={true}
        />
      </div>
      <ButtonBox
        buttons={[
          {
            id: 'Cancel-Case',
            label: props.labels.submitButtonLabel,
            onClick: () => {
              if (reason === undefined) {
                setReasonState('negative');
                return;
              }
              if (reason === 99 && reasonNote.trim() === '') {
                setReasonNoteState('negative');
                return;
              }
              props.cancelCase(reason, reasonNote);
            },
            isDisabled: reasonState !== 'positive',
            useCooldown: true,
            controlState: reasonState !== 'positive' ? 'locked' : 'positive',
          },
          {
            id: 'Cancel-Case-Buttons',
            label: props.labels.cancelButtonLabel,
            onClick: () => {
              setReasonState('neutral');
              setReason(undefined);
              setReasonNoteState('neutral');
              setReasonNote('');
              setDisableDefault(false);
              props.cancelTheCancelationOfCase();
            },
            isDisabled: false,
            controlState: 'positive',
          },
        ]}
        id="Cancel-Case-Buttons"
        className="Cancel-Case-Buttons"
        displayBorder={true}
        key={'Cancel-Case-Buttons'}
      />
    </div>
  );
};

export default CancelCase;
