import React from 'react';
import CaseLayoutRowTypes from './CaseTableRowProps.types';
import { LuClock } from 'react-icons/lu';
import {
  FaUser,
  FaHouse,
  FaMinus,
  FaCalendarDays,
  FaCircle,
} from 'react-icons/fa6';
import DateHelpers from '../../../../systemUtils/common/DateHelpers';
import { ControlState } from '../../../../sysObjects/common.types';
import { SvgBrain, SvgClock } from '../../../sharedControls/svg';
import Button from '../../../sharedControls/general/button/Button';
import StatusBlock from '../../../sharedControls/reusableBlocks/statusBlock/StatusBlock';
import StatusLabel from '../../../sharedControls/reusableBlocks/statusLabel/StatusLabel';
import ProgressSideBarTypes from '../../../sharedControls/targetedControls/progressSideBar/ProgressSideBar.types';
import ProgressSideBar from '../../../sharedControls/targetedControls/progressSideBar/ProgressSideBar';
import Expander from '../../../sharedControls/general/expander/Expander';
import AddCaseContact from '../caseContact/addCaseContact/AddCaseContact';
import { useLocation, useNavigate } from 'react-router-dom';

const CasesTableRow: React.FC<CaseLayoutRowTypes.Props> = (props) => {
  const [isExpanded, setExpandedState] = React.useState<Boolean>(false);
  const navigate = useNavigate();
  const handleExpandClick = () => {
    setExpandedState(!isExpanded);
  };
  const location = useLocation();

  const getSoonestDueDate = (
    slas: CaseLayoutRowTypes.ServiceLevelAgreement[],
  ): CaseLayoutRowTypes.ServiceLevelAgreement | undefined => {
    return slas
      .filter((sla) => !sla.completedDate && sla.dueDate != null)
      .sort((a, b) => a.dueDate!.getTime() - b.dueDate!.getTime())[0];
  };

  const getDaysUntilDue = (dueDate: number): number => {
    return Math.ceil((dueDate - Date.now()) / 86400000);
  };

  const renderSlaSummary = () => {
    let cssList = ['col', 'listStatus', 'Text-Regular'];
    const soonestDueDate = getSoonestDueDate(props.slas);
    let placement = 'none';
    let boxState = 'neutral';

    if (soonestDueDate !== undefined) {
      cssList.push('Sla-States');
      const daysUntilDue = getDaysUntilDue(soonestDueDate.dueDate!.getTime());
      if (daysUntilDue <= 5) {
        cssList.push(daysUntilDue <= 0 ? 'overdue' : 'dueSoon');
        placement = daysUntilDue <= 0 ? 'bottom' : 'middle';
        boxState = daysUntilDue <= 0 ? 'negative' : 'warning';
      } else {
        cssList.push('dueLater');
        placement = 'top';
        boxState = 'positive';
      }
    }
    return (
      <div
        className={cssList.join(' ')}
        style={{ width: '100%', height: '100%' }}
      >
        {props.slas.length > 0 && (
          <>
            <ProgressSideBar
              FieldKey="pro"
              boxState={boxState as ControlState}
              location={placement as 'none' | 'top' | 'middle' | 'bottom'}
            />
            <StatusBlock
              boxSize="small"
              boxState="positive"
              id={`${props.id}_SlaNumberIcon`}
              content={<FaMinus />}
            />
            {props.slas.length} SLAs
            <StatusBlock
              boxSize="small"
              boxState="positive"
              id={`${props.id}_SlaTimeIcon`}
              content={<LuClock />}
            />
            {soonestDueDate !== undefined &&
              DateHelpers.getLocalDateString(
                soonestDueDate.dueDate!,
                props.intl,
                'MMMM',
              )}
          </>
        )}
      </div>
    );
  };

  const getStatusProps = (): ProgressSideBarTypes.Props => {
    const soonestDueDate = getSoonestDueDate(props.slas);
    let placement = 'none';
    let boxState = 'neutral';

    if (soonestDueDate !== undefined) {
      const daysUntilDue = getDaysUntilDue(soonestDueDate.dueDate!.getTime());
      if (daysUntilDue <= 5) {
        placement = daysUntilDue <= 0 ? 'bottom' : 'middle';
        boxState = daysUntilDue <= 0 ? 'negative' : 'warning';
      } else {
        placement = 'top';
        boxState = 'positive';
      }
    }
    return {
      boxState: boxState as ControlState,
      location: placement as 'none' | 'top' | 'middle' | 'bottom',
      FieldKey: `pro_${soonestDueDate?.id || 'none'}`,
    };
  };

  const renderSlaDetails = (
    sla: CaseLayoutRowTypes.ServiceLevelAgreement,
    index: number,
  ) => {
    let cssList = ['slaDetail'];
    let state = '';
    let stateLabel = '';

    if (sla.completedDate) {
      cssList.push('dueLater');
      state = 'positive';
      stateLabel = props.labels.states.completed;
    } else {
      const daysUntilDue =
        sla.dueDate == null ? 100 : getDaysUntilDue(sla.dueDate!.getTime());
      if (daysUntilDue <= 5) {
        cssList.push(daysUntilDue <= 0 ? 'overdue' : 'dueSoon');
        state = daysUntilDue <= 0 ? 'negative' : 'warning';
        stateLabel =
          daysUntilDue <= 0
            ? props.labels.states.overDue
            : props.labels.states.dueSoon;
      } else {
        cssList.push('dueLater');
        state = 'positive';
        stateLabel = props.labels.states.inProgress;
      }
    }

    return (
      <div key={`sla_${index}`} className={cssList.join(' ')}>
        <StatusBlock
          boxSize="small"
          boxState={state as ControlState}
          content={<SvgBrain height={20} width={20} />}
        />
        {sla.name}
        <StatusLabel
          label={stateLabel}
          status={state as ControlState}
          renderAs="span"
        />
        {sla.dueDate && (
          <>
            <StatusBlock
              boxSize="small"
              boxState={state as ControlState}
              content={<SvgClock height={20} width={20} />}
            />
            {sla.dueDate
              ? DateHelpers.getLocalDateString(sla.dueDate!, props.intl, 'MMMM')
              : ''}
          </>
        )}
      </div>
    );
  };

  const renderCustomerName = () => {
    return (
      <>
        <StatusBlock
          boxSize="small"
          boxState="positive"
          id={`${props.id}_CustomerIconExpanded`}
          content={<FaUser />}
          className='Highlightable-Icon'
        />
        <span
          className='Highlightable-Name'
          data-testid={`customer-name-${props.id}`}
          onClick={() =>
            navigate(`/customers/accounts/view/Customer/${props.customerUserAccountId}?from=${encodeURIComponent(location.pathname)}`)
          }
        >
          {props.customerName}
        </span>
      </>
    )
  };

  const renderCollapsed = () => {
    return (
      <>
        <div className="col Text-Regular ">
          <StatusLabel
            label={props.statusLabel}
            renderAs="span"
            status="warning"
          />
        </div>
        <div className="col list Text-Regular Icon-Hyperlink">
          {renderCustomerName()}
        </div>
        <div className="col list Text-Regular">
          <StatusBlock
            boxSize="small"
            boxState="positive"
            id={`${props.id}_RefOrgIcon`}
            content={<FaHouse />}
          />
          {props.refOrganization}
        </div>
        {renderSlaSummary()}
        <div className="col list Text-Regular">
          {props.lastModifiedDate !== '' && (
            <>
              <StatusBlock
                boxSize="small"
                boxState="positive"
                id={`${props.id}_LastModifiedDateIcon`}
                content={<LuClock />}
              />
              {props.lastModifiedDate}
            </>
          )}
        </div>
        <div className="col">
          {props.rowButtons &&
            props.rowButtons.map((button, index) => (
              <Button
                key={`Table_Button_one_${props.id}_${index}`}
                itemKey={`Table_Button_one_${props.id}_${index}`}
                label={button.label}
                mode={button.controlState}
                clickEvent={() => {
                  if (button.onClick) {
                    button.onClick(props.id);
                  }
                }}
                useCooldown={button.useCooldown}
              />
            ))}
        </div>
      </>
    );
  };

  const renderExpanded = () => {
    return (
      <div className="details">
        <div className="col Text-Regular">{props.labels.status}</div>
        <div className="col Text-Regular ">
          <StatusLabel
            label={props.statusLabel}
            renderAs="span"
            status="warning"
          />
        </div>
        <div className="col Text-Regular">{props.labels.customerAcc}</div>
        <div className="col Text-Regular Dual-Block Icon-Hyperlink">
          {renderCustomerName()}
        </div>
        <div className="col Text-Regular">{props.labels.referrerAcc}</div>
        <div className="col Text-Regular Dual-Block">
          <StatusBlock
            boxSize="small"
            boxState="positive"
            id={`${props.id}_RefOrgIconExpanded`}
            content={<FaHouse />}
          />
          {props.refOrganization}
        </div>
        <div className="col Text-Regular">{props.labels.due}</div>

        <div
          className={`col Text-Regular ${props.slas.length > 0 ? 'List-Slas' : 'List-Block'
            }`}
        >
          {props.slas.length > 0 && (
            <ProgressSideBar
              {...getStatusProps()}
              style={{ gridRow: `1 / ${props.slas.length + 2}` }}
            />
          )}
          <StatusBlock
            boxSize="small"
            boxState="positive"
            id={`${props.id}_SlaNumberIconExpanded`}
            content={<FaMinus />}
          />
          {`${props.slas.length} ${props.labels.sla}`}
          {props.slas.length > 0 &&
            props.slas.map((sla, index) => {
              return renderSlaDetails(sla, index);
            })}
        </div>
        <div className="col Text-Regular">{props.labels.lastModifiedDate}</div>
        <div className="col Text-Regular Dual-Block">
          <StatusBlock
            boxSize="small"
            boxState="positive"
            id={`${props.id}_LastModifiedDateIconExpanded`}
            content={<FaCalendarDays />}
          />
          {props.lastModifiedDate}

          <StatusBlock
            boxSize="small"
            boxState="positive"
            id={`${props.id}_LastModifiedTimeIconExpanded`}
            content={<LuClock />}
          />
          {props.lastModifiedTime}
        </div>
        {props.contacts && (
          <>
            <div className="col Text-Regular">{props.labels.contacts}</div>
            <div className="col Text-Regular Contacts">
              <ProgressSideBar
                FieldKey={`${props.id}_SideBar`}
                boxState="neutral"
                location="none"
                style={{ gridRow: `1 / ${props.contacts.length + 1}` }}
              />
              {(
                props.contacts.map((contact, index) => (
                  <div key={`contact_Row_${props.id}_${index}`}>
                    <div className="contact-information">
                      <div className="contact-name">
                        <StatusBlock
                          boxSize="small"
                          boxState="neutral"
                          id={`contact_stateBox_${props.id}_${index}`}
                          content={<FaCircle />}
                        />
                        {contact.name}
                      </div>
                      {contact.email ? (
                        <div className="contact-email">
                          <StatusLabel
                            label={props.labels.email}
                            renderAs="span"
                            status="neutral"
                            key={`contact_Row_${props.id}_${index}H`}
                          />
                          {contact.email}
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <div className="contact-relationship Text-Understated">
                        {contact.relationship}
                      </div>
                      {contact.phone ? (
                        <div className="contact-phone">
                          <StatusLabel
                            label={props.labels.phone}
                            renderAs="span"
                            status="neutral"
                            key={`contact_Row_${props.id}_${index}H_P`}
                          />
                          {contact.phone}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>

                    <div className="Add-Case-Contact-Form">
                    </div>
                  </div>
                )))}
            </div>
          </>
        )}
        {props.buttons && (
          <>
            <div className="col Text-Regular"> {props.labels.actions}</div>
            <div className="col Button-Block">
              {props.buttons.map((button, index) => (
                <Button
                  key={`Table_Button_${props.id}_${index}`}
                  itemKey={`Table_Button_${props.id}_${index}`}
                  label={button.label}
                  mode={button.controlState}
                  clickEvent={() => {
                    if (button.onClick) {
                      button.onClick(props.id);
                    }
                  }}
                  useCooldown={button.useCooldown}
                />
              ))}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div className={`Case-Row ${isExpanded && 'Expanded'}`}>
        <Expander
          key={`${props.id}_Status`}
          id={`${props.id}_Status`}
          isExpanded={isExpanded}
          eventHandler={{ onClick: handleExpandClick }}
        />

        <div className="col Text-Regular id">{props.idLabel}</div>
        {!isExpanded ? renderCollapsed() : renderExpanded()}
      </div>
    </>
  );
};

export default CasesTableRow;
