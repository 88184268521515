import React, { AnchorHTMLAttributes, MouseEventHandler } from 'react';
import AddDocumentTypes from './AddDocument.types';
import './AddDocument.css';
import DocumentTypes from '../../../../../sysObjects/apiModels/UploadDocument.types';
import { KeyValuePair } from '../../../../../sysObjects/common.types';
import Enumerations from '../../../../../systemUtils/common/CommonHelpers';
import FormTextCapture from '../../../../sharedControls/formControls/formTextCapture/FormTextCapture';
import FormTextArea from '../../../../sharedControls/formControls/formTextArea/FormTextArea';
import FormDropDown from '../../../../sharedControls/formControls/formDropDown/FormDropDown';
import ButtonBox from '../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';
import StatusBlock from '../../../../sharedControls/reusableBlocks/statusBlock/StatusBlock';
import RowLoader from '../../../../sharedControls/general/loading/rowLoader/RowLoader';
import Expander from '../../../../sharedControls/general/expander/Expander';

const AddDocument: React.FC<AddDocumentTypes.Props> = (props) => {
  const [isExpanded, setExpanded] = React.useState<boolean>(false);
  const [isUploading, setUploading] = React.useState<boolean>(false);
  const [selectedFile, setSelectedFile] =
    React.useState<DocumentTypes.UploadModal | null>(null);
  const [documentTypes, setDocumentTypes] = React.useState<
    KeyValuePair<number>[]
  >([]);
  const [documentVisibility, setDocumentVisibility] = React.useState<
    KeyValuePair<number>[]
  >([]);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [showError, setShowError] = React.useState<boolean>(false);
  React.useEffect(() => {
    setDocumentTypes(Enumerations.getDocumentTypes(props.locale));
    setDocumentVisibility(
      Enumerations.getDocumentVisibilityState(props.locale),
    );
  }, []);

  const handleChooseFileClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    const fileInput = document.getElementById(
      'file_Case_Upload',
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  const validateFileSize = (file: File) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (fileExtension && props.labels.formats.includes(fileExtension)) {
      if (file.size > props.maxSizeInMB * 1024 * 1024) {
        setErrorMessage(props.labels.maxSizeError);
        setShowError(true);
        return false;
      }
    } else {
      setErrorMessage(props.labels.invalid);
      setShowError(true);
      return false;
    }
    return true;
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (
      e.dataTransfer.files.length > 0 &&
      validateFileSize(e.dataTransfer.files[0])
    ) {
      setSelectedFile({
        File: e.dataTransfer.files[0],
        UserSpecifiedFileName: e.dataTransfer.files[0].name,
        CaseId: props.caseId,
        DocumentType: 0,
        DocumentVisibility: 0,
      });
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.files &&
      e.target.files.length > 0 &&
      validateFileSize(e.target.files[0])
    ) {
      setSelectedFile({
        File: e.target.files[0],
        UserSpecifiedFileName: e.target.files[0].name,
        CaseId: props.caseId,
        DocumentType: 0,
        DocumentVisibility: 0,
      });
    }
  };

  const renderSelectedFile = () => {
    return (
      <div className="Details">
        <FormTextCapture
          fieldId="fileName"
          id="fileName"
          label={props.labels.fileName}
          displayMode="Column"
          value={selectedFile!.UserSpecifiedFileName}
          readonly={true}
          textInputType={'text'}
        />
        <FormTextArea
          fieldId="accompanyingNotes"
          id="accompanyingNotes"
          label={props.labels.notes}
          displayMode="Column"
          value={selectedFile!.AccompanyingNotes || ''}
          onChange={(e) =>
            setSelectedFile({
              ...selectedFile!,
              AccompanyingNotes: e.value,
            })
          }
        />
        <FormDropDown
          fieldId="documentType"
          id="documentType"
          items={documentTypes}
          label={props.labels.typeLabel}
          displayMode="Column"
          value={selectedFile?.DocumentType}
          onChange={(e) =>
            setSelectedFile({
              ...selectedFile!,
              DocumentType: e.value as number,
            })
          }
        />
        <FormDropDown
          fieldId="documentVisibility"
          id="documentVisibility"
          items={documentVisibility}
          label={props.labels.visibility}
          displayMode="Column"
          value={selectedFile?.DocumentVisibility}
          onChange={(e) =>
            setSelectedFile({
              ...selectedFile!,
              DocumentVisibility: e.value as number,
            })
          }
        />
        <ButtonBox
          id="documentAdd"
          displayBorder={true}
          key={`documentAddButtonBox`}
          buttons={[
            {
              controlState: 'positive',
              id: 'UploadOk',
              label: props.labels.upload,
              onClick: () => {
                if (selectedFile) {
                  setUploading(true);
                  props.events
                    .uploadDocument({
                      DocumentType: selectedFile.DocumentType,
                      DocumentVisibility: selectedFile.DocumentVisibility,
                      File: selectedFile.File,
                      AccompanyingNotes: selectedFile.AccompanyingNotes,
                      CaseId: selectedFile.CaseId,
                      UserSpecifiedFileName: selectedFile.UserSpecifiedFileName,
                    })
                    .then((rst) => {
                      if (rst.isSuccess) {
                        setSelectedFile(null);
                        setExpanded(false);
                        /** TODO: When we wire up the list we'll insert the record locally to save the reloading of the entity */
                      }
                    })
                    .finally(() => {
                      setUploading(false);
                    });
                }
              },
            },
            {
              id: 'UploadCancel',
              controlState: 'positive',
              label: props.labels.cancel,
              onClick: () => {
                setSelectedFile(null);
                setShowError(false);
              },
            },
          ]}
        />
      </div>
    );
  };

  const renderNoFileSelected = () => {
    return (
      <>
        {showError && (
          <div className="error">
            <StatusBlock
              boxSize="small"
              boxState="negative"
              showIcon={true}
              allowHover={false}
              content={props.labels.invalid}
            />
            <div className="errorMessage"> {errorMessage || ''}</div>
          </div>
        )}
        <div
          className="upload-content Action-Regular"
          onDrop={handleDrop}
          onDragOver={(event) => event.preventDefault()}
        >
          <p className="Drag-Info">
            {`${props.labels.dragDrop} `}
            <a
              href="#"
              onClick={handleChooseFileClick}
              className="Action-AllCaps"
            >
              {props.labels.clickHere}
            </a>
          </p>
          <input
            type="file"
            id={`file_Case_Upload`}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <div className="upload-info">
            <div>
              {`${props.labels.formatsMessage} ${props.labels.formats.join(
                ', ',
              )}`}
            </div>
            <div>{`${props.labels.maxSizeLabel} ${props.maxSizeInMB}${props.labels.maxSize}`}</div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="Document-Uploader">
      {isUploading ? (
        <RowLoader size="medium" />
      ) : (
        <>
          <Expander
            key={`FileUpload_Status`}
            id={`FileUpload_Status`}
            isExpanded={isExpanded}
            usePlusIcon={true}
            eventHandler={{
              onClick: () => {
                if (isExpanded) {
                  {
                    setSelectedFile(null);
                    setShowError(false);
                  }
                }
                setExpanded(!isExpanded);
              },
            }}
          />
          <div className="Action-Title Text-Regular">
            <span className="addLabel">{props.labels.addFile}</span>
          </div>
          {isExpanded
            ? selectedFile !== null
              ? renderSelectedFile()
              : renderNoFileSelected()
            : null}
        </>
      )}
    </div>
  );
};

export default AddDocument;
