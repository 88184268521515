import { FunctionalResult } from '../../sysObjects/FunctionalResult';
import settings from '../../config/services.json';
import { getHostConfigAsync } from '../common/HostConfigActions';
import { LocalEnumerations, getDate } from '../common/CommonHelpers';
import {
  CaseDetail_Types,
  Case_Types,
} from '../../sysObjects/apiModels/Case.types';
import { ServicesToFulfill_Types } from '../../sysObjects/apiModels/ServicesToFulfill.types';
import BillableItem_Types from '../../sysObjects/apiModels/BillableItem.types';
import CaseNote_Types from '../../sysObjects/apiModels/CaseNote.types';
import {
  buildODataQuery,
  confirmAction,
  createAction,
  deleteAction,
  fetchAction,
  updateAction,
} from '../common/APIHelper';
import oDataTypes from '../../sysObjects/apiModels/oDataTypes';
import System_Types from '../../sysObjects/apiModels/System.types';
import CaseContact_Types from '../../sysObjects/apiModels/CaseContact.types';
import logger from '../Logger';
import CaseSummaryTypes from '../../systemComponents/targetedPageControls/case/caseSummary/CaseSummary.types';
import CaseOutcome_Types from '../../sysObjects/apiModels/CaseOutcome.types';
export namespace CaseUtils {
  /**
   * Finds cases asynchronously based on selected query items.
   * @param headerInfo
   * @param query
   * @returns
   */
  export const findCasesAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    query?: oDataTypes.ODataEnabledEndPointQuery<CaseDetail_Types.findCaseQuery>
  ): Promise<
    FunctionalResult<oDataTypes.PagedResult<Case_Types.FindCaseResult>>
  > => {
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    let queryParams: string[] = [];

    if (query?.apiQuery) {
      if (query.apiQuery.caseManagerId != null) {
        queryParams.push(
          `${settings.Cases.find.queryManager}=${encodeURIComponent(
            query.apiQuery.caseManagerId
          )}`
        );
      }

      if (query.apiQuery.customerId != null) {
        queryParams.push(
          `${settings.Cases.find.queryCustomerId}=${encodeURIComponent(
            query.apiQuery.customerId
          )}`
        );
      }

      if (query.apiQuery.referrerOrganisationId != null) {
        queryParams.push(
          `${settings.Cases.find.queryManager}=${encodeURIComponent(
            query.apiQuery.referrerOrganisationId
          )}`
        );
      }

      if (query.apiQuery.assessorId != null) {
        queryParams.push(
          `${settings.Cases.find.queryAssessorId}=${encodeURIComponent(
            query.apiQuery.assessorId
          )}`
        );
      }

      if (query.apiQuery.caseReference != null) {
        queryParams.push(
          `${settings.Cases.find.queryCaseReference}=${encodeURIComponent(
            query.apiQuery.caseReference
          )}`
        );
      }

      if (query.apiQuery.searchText != null) {
        queryParams.push(
          `${settings.Cases.find.querySearchText}=${encodeURIComponent(
            query.apiQuery.searchText
          )}`
        );
      }

      if (query.apiQuery.queryStatus && query.apiQuery.queryStatus.length > 0) {
        query.apiQuery.queryStatus.forEach((status) => {
          queryParams.push(
            `${settings.Cases.find.queryStatus}=${encodeURIComponent(
              status.toString()
            )}`
          );
        });
      }
    }

    if (query?.oDataQuery) {
      queryParams = queryParams.concat(buildODataQuery(query.oDataQuery));
    }
    let queryPath = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Cases.find.path!}${queryPath}`,
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * Gets a case.
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header information containing the user and access token.
   * @param { string } id - the id of the case to get
   * @returns The result of the action
   */
  export const getCaseAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    id: string
  ): Promise<FunctionalResult<Case_Types.RetrievedCase>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Cases.read.path!}${id}`,
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
    });
  };
  /**
   * Saves the Billing Details.
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header information containing the user and access token.
   * @param object The record to save.
   * @returns The result of the action
   */
  export const saveReferralCaseAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    obj: Case_Types.BaseCase
  ): Promise<FunctionalResult<string>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return createAction({
      hostPath: host!.result!.path,
      apiPath: settings.Cases.create.path!,
      userId: headerInfo.result.userId,
      formData: obj,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * Completes the service selection for a given case asynchronously.
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header information containing the user and access token.
   * @param caseId - The ID of the case.
   * @returns A promise that resolves to a FunctionalResult<void> indicating the result of the operation.
   */
  export const completeServiceSelectionAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    caseId: string
  ): Promise<FunctionalResult<void>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return confirmAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Cases.ServicesAssigned.path!}/${caseId}`,
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * Gets the triage details for a case
   * @param {string} id - The id of the case to get the triage for
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header information containing the user and access token.
   * @returns {FunctionalResult<CaseDetail_Types.TriageDetail>} The result of the action
   */
  export const getTriageByCaseAsync = async (
    id: string,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<CaseDetail_Types.RetrievedTriage>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return fetchAction(
      {
        hostPath: host!.result!.path,
        apiPath: `${settings.Cases.Triage.path!}?${
          settings.Cases.Triage.queryId
        }=${id}`,
        userId: headerInfo.result.userId,
        accessToken: headerInfo.result.token,
      },
      createEmptyRetrievedTriage(id, getDate())
    );
  };

  /**
   * Saves the triage details.
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header information containing the user and access token.
   * @param {CaseDetail_Types.TriageDetail} obj - The triage details to save
   * @returns The result of the action
   */
  export const saveTriageAsync = async (
    obj: CaseDetail_Types.TriageDetail,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<string>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return updateAction({
      hostPath: host!.result!.path,
      apiPath: settings.Cases.Triage.path,
      userId: headerInfo.result.userId,
      formData: obj,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * DEPRECATED: Use ServicesToFulfillActions.createAsync in ServicesToFulfillActions.tsx instead.
   */
  export const addServiceToFulfillToCaseAsync = async (
    obj: ServicesToFulfill_Types.ServiceToFulfill,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<string>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return createAction({
      hostPath: host!.result!.path,
      apiPath: settings.Cases.AddServiceToFulfill.path,
      userId: headerInfo.result.userId,
      formData: obj,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * Creates a new manual billable item for a case.
   * @param obj The billable item.
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header information containing the user and access token.
   * @returns The id of the created billable item.
   */
  export const addManualBillableItemToCaseAsync = async (
    obj: BillableItem_Types.CreateBillableItem,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<string>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return createAction({
      hostPath: host!.result!.path,
      apiPath: settings.BillableItems.CreateManualBillableItem.path,
      userId: headerInfo.result.userId,
      formData: obj,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * Finds case notes for a specific case asynchronously.
   *
   * @param caseId - The ID of the case.
   * @param documentId - The ID of the document (optional).
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header information containing the user and access token.
   * @returns A promise that resolves to a `FunctionalResult` containing a collection of retrieved case notes.
   */
  export const findCaseNotesForCaseAsync = async (
    caseId: string,
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    documentId?: string
  ): Promise<FunctionalResult<CaseNote_Types.RetrievedNote[]>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    let queryParams: string[] = [];

    if (documentId) {
      queryParams.push(
        `${settings.Cases.FindCaseNotes.queryDocumentId}=${caseId}`
      );
    }

    const queryPath = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Cases.FindCaseNotes.path!}/${caseId}${queryPath}`,
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * Adds a case note to a case asynchronously.
   *
   * @param note - The case note to be added.
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header information containing the user and access token.
   * @returns A promise that resolves to a FunctionalResult containing a string.
   */
  export const addCaseNoteToCaseAsync = async (
    note: CaseNote_Types.Base,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<string>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    if (note.documentId === null || note.documentId === '') {
      note.documentId = undefined;
    }

    return createAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Cases.AddCaseNote.path!}`,
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
      formData: note,
    });
  };

  /**
   * Assigns a case manager to a case asynchronously.
   *
   * @param userAccountId - The ID of the user account to assign as the case manager.
   * @param headerDetails - The headers for the API call.
   * @returns A promise that resolves to a FunctionalResult.
   */
  export const assignCaseManagerAsync = async (
    userAccountId: string,
    caseId: string,
    headerDetails: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<void>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      logger.error('Failed to load host configuration');
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (headerDetails.isFailure) {
      logger.error('Failed to get headers');
      return FunctionalResult.Error('Failed to get headers');
    }

    const formData = {
      userAccountId,
    } as Case_Types.AssignCaseManager;

    return confirmAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Cases.AssignCaseManager.path!}/${caseId}`,
      userId: headerDetails.result!.userId,
      accessToken: headerDetails.result!.token,
      formData: formData,
    });
  };

  /**
   * Creates a empty model to bind to the create form
   * @returns {Case_Types.BaseCase} The empty case model
   */
  export const createDefaultReferralCase = (): Case_Types.BaseCase => {
    return {
      caseIdentifiers: {
        customerAccountId: '',
        referrerOrganisationId: '',
        referrerAccountId: '',
        defaultBillingOrganisationId: '',
      },
      referrerCaseReference: '',
      suspectedConditions: [],
      servicesRequired: [],
      appointmentFormat: null,
      appointmentFormatOther: '',
      referralReason: null,
      referralReasonOther: '',
      caseContacts: [],
      notes: [],
      servicesToFulfill: [],
    };
  };

  export const stateIsInServiceEditableCaseStates = (
    status: number
  ): boolean => {
    return [
      LocalEnumerations.CaseStatuses.NewReferral,
      LocalEnumerations.CaseStatuses.CaseManagerAssigned,
    ].includes(status);
  };

  export const roleIsInCaseManagerRoles = (role: number): boolean => {
    return [
      LocalEnumerations.Roles.CaseManager,
      LocalEnumerations.Roles.SuperUser,
    ].includes(role);
  };

  export const roleIsInAssessorRoles = (role: number): boolean => {
    return [
      LocalEnumerations.Roles.Assessor,
      LocalEnumerations.Roles.AssociateAssessor,
    ].includes(role);
  };

  /**
   * Creates an empty model for the UI to bind to *
   * @param {string} caseId The case to bind the triage to
   * @param date The date to bind to the triage - this is passed in to enable more robust testing
   * @returns {CaseDetail_Types.TriageDetail} The empty triage model
   */
  export const createEmptyTriage = (
    caseId: string,
    date: Date
  ): CaseDetail_Types.TriageDetail => {
    return {
      caseId,
      paymentApproved: false,
      paymentApprovedDate: date,
      consentReceived: false,
      consentReceivedDate: date,
      consentApprovedDate: date,
      questionsCompleted: false,
      purchaseOrderNumber: '',
    };
  };

  /**
   * Creates an empty model for the UI to bind to *
   * @param {string} caseId The case to bind the triage to
   * @param date The date to bind to the triage - this is passed in to enable more robust testing
   * @returns {CaseDetail_Types.RetrievedTriage} The empty triage model
   */
  export const createEmptyRetrievedTriage = (
    caseId: string,
    date: Date
  ): CaseDetail_Types.RetrievedTriage => {
    return {
      currentCaseStatus: undefined,
      caseTriage: CaseUtils.createEmptyTriage(caseId, date),
    };
  };

  /**
   * Adds a case contact to a case asynchronously.
   *
   * @param caseId - The ID of the case to add the contact to.
   * @param case contact - The case contact to be added.
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header information containing the user and access token.
   * @returns A promise that resolves to a FunctionalResult containing a string.
   */
  export const addCaseContactToCaseAsync = async (
    caseId: string,
    caseContact: CaseContact_Types.Base,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<string>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return createAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Cases.AddCaseContact.path!}/${caseId}`,
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
      formData: caseContact,
    });
  };

  /**
   * Removes a case contact from a case asynchronously.
   *
   * @param caseId - The ID of the case .
   * @param id - the id of the contact to remove
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header information containing the user and access token.
   * @returns A promise that resolves to a FunctionalResult containing a string.
   */
  export const removeCaseContactFromCaseAsync = async (
    caseId: string,
    id: string,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<void>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return deleteAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Cases.RemoveCaseContact.path!}/${caseId}?${
        settings.Cases.RemoveCaseContact.queryId
      }=${id}`,
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * Gets the contacts from a case asynchronously.
   *
   * @param caseId - The ID of the case .
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header information containing the user and access token.
   * @returns A promise that resolves to a FunctionalResult containing a string.
   */
  export const retrieveCaseContactsFromCaseAsync = async (
    caseId: string,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<CaseDetail_Types.CaseContact>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Cases.RetrieveCaseContact.path!}/${caseId}`,
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
    });
  };

  export const completeCaseAsync = async (
    caseId: string,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<void>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return confirmAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Cases.complete.path!}/${caseId}`,
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * Cancels the case asynchronously.
   * @param caseId The id of the case to cancel
   * @param details The reason and notes for the cancellation
   * @param headerInfo The header information containing the user and access token to set in the api call.
   * @returns A promise that resolves to a FunctionalResult<void> indicating the result of the operation.
   */
  export const cancelCaseAsync = async (
    caseId: string,
    details: CaseDetail_Types.CancelCaseDetail,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<void>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return confirmAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Cases.cancel.path!}/${caseId}`,
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
      formData: details,
    });
  };

  export const getCaseSummaryAsync = async (
    caseId: string,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<CaseSummaryTypes.CaseSummary>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Cases.getSummary.path!}/${caseId}`,
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * Gets the case outcomes for a case asynchronously.
   *
   * @param caseId - The ID of the case.
   * @param headerInfo - The header information containing the user and access token.
   * @returns A promise that resolves to a `FunctionalResult` containing a collection of retrieved case outcomes.
   */
  export const getCaseOutcomesAsync = async (
    caseId: string,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<CaseOutcome_Types.Diagnosis[]>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Cases.getOutcomes.path!}/${caseId}`,
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * Adds a case outcome to a case asynchronously.
   *
   * @param caseId The ID of the case to add the outcome to.
   * @param outcome The outcome to be added.
   * @param headerInfo The header information containing the user and access token.
   * @returns A promise that resolves to a FunctionalResult containing a string.
   */
  export const addCaseOutcomeAsync = async (
    outcome: CaseOutcome_Types.CreateOutcome,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<string>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return createAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Cases.addOutcome.path!}`,
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
      formData: outcome,
    });
  };

  /**
   * Removes a case outcome from a case asynchronously.
   *
   * @param caseId The ID of the case to remove the outcome from.
   * @param outcomeId The ID of the outcome to remove.
   * @param headerInfo The header information containing the user and access token.
   * @returns A promise that resolves to a FunctionalResult<void> indicating the result of the operation.
   */
  export const removeCaseOutcomeAsync = async (
    caseId: string,
    outcomeId: string,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<void>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return deleteAction({
      hostPath: host!.result!.path,
      apiPath: settings.Cases.removeOutcome.path.replace('{caseId}', caseId).replace('{outcomeId}', outcomeId),
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * Creates an empty model for the UI to bind to.
   * @param {string} userId The id of the user creating the case note
   * @returns {CaseNote_Types.Base} The empty case note model
   */
  export const createEmptyAddDiagnosesOutObject =
    (): CaseOutcome_Types.BaseOutcome => {
      return {
        dateDiagnosed: getDate().toISOString(),
        diagnosedById: '',
        notes: '',
        diagnosedCondition: undefined,
      };
    };
}

/**
 * Determines if a case has any invoiced billable items.
 * @param {Case_Types.BaseCase} baseCase The case to check.
 * @returns {boolean} True if the case has any invoiced billable items.
 */
export const doesCaseContainInvoicedBillableItems = (
  baseCase: Case_Types.BaseCase
): boolean => {
  return baseCase.servicesToFulfill.some((service) => {
    return (
      service.billableItems &&
      service?.billableItems.some((billableItem) => billableItem.isInvoiced)
    );
  });
};

export default CaseUtils;
