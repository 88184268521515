import React, { useEffect, useState } from 'react';
import ListTable from '../../../../sharedControls/tables/listTable/ListTable';
import OutComesTab_Types from './OutComesTab.types';
import AddOutComes from '../../outcomes/addOutComes/AddOutComes';
import './OutComesTab.css';
import CaseOutcome_Types from '../../../../../sysObjects/apiModels/CaseOutcome.types';
import ListTableRow from '../../../../sharedControls/tables/listTable/listTableRow/ListTableRow';
import {
  getInitails,
  LocalEnumerations,
} from '../../../../../systemUtils/common/CommonHelpers';
import DateHelpers from '../../../../../systemUtils/common/DateHelpers';
import StatusBlock from '../../../../sharedControls/reusableBlocks/statusBlock/StatusBlock';
import { SvgClock } from '../../../../sharedControls/svg';
import SideBar from '../../../../sharedControls/reusableBlocks/sideBar/SideBar';
import ButtonBox from '../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';
import SortableHeader from '../../../../sharedControls/tables/listTable/headers/sortable/SortableHeader';
import {
  ColDetails,
  colSearchDetails,
} from '../../../../../sysObjects/common.types';
import StandardHeader from '../../../../sharedControls/tables/listTable/headers/standard/StandardHeader';
import { sortTable } from '../../../../../systemUtils/common/SortSearchHelpers';

import './OutComesTab.css';
const OutcomesTab: React.FC<OutComesTab_Types.Props> = (props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [reload, setReload] = React.useState(true);
  const [rows, setRows] = React.useState<CaseOutcome_Types.DiagnosisRow[]>([]);
  const [confirm, setConfirm] = React.useState(false);
  const [sortColumnDetails, setSortColumnDetails] = useState<colSearchDetails>({
    field: 'date',
    searchDirection: 'desc',
    searchType: 'date',
  });
  useEffect(() => {
    if (!reload) {
      return;
    }

    props.eventsHandler
      .onReloadOutComes()
      .then((rst) => {
        if (rst.isSuccess) {
          console.log(rst.result);
          setRows(
            (rst.result || []).map((diagnosis) => ({
              id: diagnosis.id,
              conditionLabel:
                props.conditionList.find(
                  (c) => c.key === diagnosis.diagnosedCondition
                )?.value || '',
              addedByLabel: `${diagnosis.createdBySummary.name} ${diagnosis.createdBySummary.surname}`,
              diagnosisByLabel: `${diagnosis.diagnosedBySummary.name} ${diagnosis.diagnosedBySummary.surname}`,
              notes: diagnosis.notes,
              dateLabel: DateHelpers.getLocalDateString(
                diagnosis.dateDiagnosed,
                props.int,
                'MMMM'
              ),
              date: new Date(diagnosis.dateDiagnosed),
              isExpanded: false,
            }))
          );
        }
        setIsLoading(false);
        setReload(false);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
        setReload(false);
      });
  }, [reload]);

  return (
    <ListTable
      isLoading={isLoading}
      className="OutComesTable"
      labels={props.labels.table.common}
      pagingDetails={props.pagingDetails}
      rows={sortTable(
        rows,
        sortColumnDetails.field as keyof CaseOutcome_Types.DiagnosisRow,
        sortColumnDetails.searchDirection === 'none'
          ? 'asc'
          : sortColumnDetails.searchDirection,
        sortColumnDetails.searchType
      ).map((row) => (
        <ListTableRow
          key={row.id}
          isExpanded={row.isExpanded}
          events={{
            OnExpandChange: () => {
              setRows(
                rows.map((r) =>
                  r.id === row.id ? { ...r, isExpanded: !r.isExpanded } : r
                )
              );
            },
          }}
          id={row.id}
          isButtonRowExpanded={false}
        >
          <ListTableRow.Expanded>
            <div className="ConditionHeader Text-Regular">
              <span>{row.conditionLabel}</span>
            </div>
            <div>{props.labels.addOutComes.diagnosisDate}</div>
            <div>
              <SideBar
                FieldKey="dateLabel-SideBar"
                boxState="neutral"
                includeInnerBar={false}
              />
              <StatusBlock
                boxSize="small"
                boxState="positive"
                showIcon={false}
                allowHover={false}
                content={<SvgClock className="icon" />}
              />
              <span className="Text-Regular">{row.dateLabel}</span>
            </div>
            <div>{props.labels.addOutComes.diagnosisByLabel}</div>
            <div>
              <SideBar
                FieldKey="diagnosisByLabel-SideBar"
                boxState="neutral"
                includeInnerBar={false}
              />
              <StatusBlock
                boxSize="small"
                boxState="locked"
                showIcon={false}
                allowHover={false}
                className="Status-User-Int"
                content={getInitails(row.diagnosisByLabel)}
              />
              <span>{row.diagnosisByLabel}</span>
            </div>
            <div>{props.labels.addOutComes.addBy}</div>
            <div>
              <SideBar
                FieldKey="diagnosisByLabel-SideBar"
                boxState="neutral"
                includeInnerBar={false}
              />
              <StatusBlock
                boxSize="small"
                boxState="locked"
                showIcon={false}
                allowHover={false}
                className="Status-User-Int"
                content={getInitails(row.addedByLabel)}
              />
              <span>{row.addedByLabel}</span>
            </div>
            <div>{props.labels.addOutComes.noteLabel}</div>
            <div>
              <SideBar
                FieldKey="NoteLabel-SideBar"
                boxState="neutral"
                includeInnerBar={false}
              />
              <span>{row.notes}</span>
            </div>
            {props.canRemove && (
              <>
                <div>{props.labels.addOutComes.actionLabel}</div>
                <div>
                  <ButtonBox
                    id="RemoveOutCome"
                    key="RemoveOutCome"
                    className="RemoveOutCome"
                    displayBorder={true}
                    buttons={[
                      {
                        id: 'RemoveOutCome',
                        controlState: 'negative',
                        label: props.labels.removeOutcome,
                        isChecked: confirm,
                        onClick: () => {
                          setConfirm(!confirm);
                        },
                      },
                    ]}
                  />
                </div>
              </>
            )}
            {confirm && (
              <ButtonBox
                id="confirmRemoveOutCome"
                key="confirmRemoveOutCome"
                className="confirmRemoveOutCome"
                displayBorder={true}
                buttons={[
                  {
                    id: 'confirmRemoveOutCome',
                    controlState: 'negative',
                    label: props.labels.confirmRemoveOutcome,
                    onClick: () => {
                      props.eventsHandler
                        .onRemoveOutCome(row.id)
                        .then((rst) => {
                          if (rst.isSuccess) {
                            setIsLoading(true);
                            setReload(true);
                          }
                          return rst;
                        });
                    },
                  },
                  {
                    id: 'CancelOutCome',
                    controlState: 'positive',
                    label: props.labels.cancelRemoveOutcome,
                    onClick: () => {
                      setConfirm(false);
                    },
                  },
                ]}
              />
            )}

          </ListTableRow.Expanded>
          <ListTableRow.Collapsed>
            <div>
              <span>{row.conditionLabel}</span>
            </div>
            <div className="Status-Span">
              <StatusBlock
                boxSize="small"
                boxState="positive"
                showIcon={false}
                allowHover={false}
                content={<SvgClock className="icon" />}
              />
              <span>{row.dateLabel}</span>
            </div>
            <div className="Status-Span-End">
              <StatusBlock
                boxSize="small"
                boxState="locked"
                showIcon={false}
                allowHover={false}
                className="Status-User-Int"
                content={getInitails(row.diagnosisByLabel)}
              />
              <span>{row.diagnosisByLabel}</span>
            </div>
            <div className="Status-Span-End">
              <StatusBlock
                boxSize="small"
                boxState="locked"
                showIcon={false}
                allowHover={false}
                className="Status-User-Int"
                content={getInitails(row.addedByLabel)}
              />
              <span>{row.addedByLabel}</span>
            </div>
          </ListTableRow.Collapsed>
        </ListTableRow>
      ))}
      defaultItemPlacing="bottom"
      defaultItem={
        props.canAdd && (
          <AddOutComes
            labels={props.labels.addOutComes}
            assessors={props.assessorList
              .filter(
                (a) =>
                  a.role === LocalEnumerations.Roles.Assessor ||
                  a.role === LocalEnumerations.Roles.AssociateAssessor
              )
              .map((a) => ({ key: a.key, value: a.value }))}
            conditions={props.conditionList}
            eventsHandler={{
              onAddOutCome: (outcome: CaseOutcome_Types.BaseOutcome) =>
                props.eventsHandler.onAddOutCome(outcome).then((rst) => {
                  if (rst.isSuccess) {
                    setIsLoading(true);
                    setReload(true);
                  }
                  return rst;
                }),
            }}
            userId={props.userId}
            userName={props.userName}
          />
        )
      }
    >
      <ListTable.Headers>
        <div className="Heading Text-Regular" />
        {props.labels.cols.map((col: ColDetails, index: number) => {
          return col.style === 'sortable' ? (
            <SortableHeader
              id={col.id}
              key={`${col.id}-${index}`}
              name={col.name}
              fieldName={col.field}
              searchType={col.searchType}
              sortDirection={
                col.field === sortColumnDetails.field
                  ? sortColumnDetails.searchDirection
                  : 'none'
              }
              onSortSelected={(name, searchType, sortDirection) => {
                setSortColumnDetails({
                  field: name,
                  searchDirection: sortDirection,
                  searchType: searchType as
                    | 'string'
                    | 'number'
                    | 'boolean'
                    | 'date',
                });
              }}
            />
          ) : (
            <StandardHeader
              id={col.id}
              name={col.name}
              key={`${col.id}-${index}`}
            />
          );
        })}
      </ListTable.Headers>
    </ListTable>
  );
};

export default OutcomesTab;
