import { FunctionalResult } from '../../sysObjects/FunctionalResult';
import DocumentTypes from '../../sysObjects/apiModels/UploadDocument.types';
import oDataTypes from '../../sysObjects/apiModels/oDataTypes';
import {
  AuthenticatedAPIModel,
  BaseAPIModel,
  ConfirmAPIModel,
  CreateAPIModel,
  DeleteAPIModel,
  updateAPIModel,
  UploadDocumentModel,
} from '../../sysObjects/common.types';
import logger from '../Logger';
const authorizationHeader = 'Authorization';

const createBearerToken = (accessToken: string) => {
  return `Bearer ${accessToken}`;
};

/**
 * Builds the OData query string from the query object
 * @param query - The query object to build the query string from
 * @returns The query string to append to the URL
 */
export const buildODataQuery = (query: oDataTypes.ODataQueryOptions) => {
  let queries: string[] = [];

   if (query.sortDetails) {
     queries.push(`$orderby=${query.sortDetails.orderBy} ${query.sortDetails.orderDirection}`);
   }



  if (query.pagingDetails) {
    queries.push(`$skip=${query.pagingDetails.skip}`);
    queries.push(`$top=${query.pagingDetails.top}`);
  }

  return queries.join('&');
};

/**
 * Handles HTTP confirmation actions. This doesn't read the body
 * @param {CreateAPIModel} the Api Details to pass in
 * @returns {Promise<FunctionalResult<void>>} - A FunctionalResult representing the outcome of the confirm operation.
 */
export const confirmAction = async (
  obj: ConfirmAPIModel,
): Promise<FunctionalResult<void>> => {
  try {
    const method = obj.method || 'POST';
    const options: RequestInit = {
      method: method,
      headers: obj.userId
        ? {
            'X-User-Identifier': obj.userId,
            'Content-Type': 'application/json',
            [authorizationHeader]: createBearerToken(obj.accessToken),
          }
        : {
            'Content-Type': 'application/json',
            [authorizationHeader]: createBearerToken(obj.accessToken),
          },
    };

    if (obj.formData && method !== 'GET' && method !== 'HEAD') {
      options.body = JSON.stringify({ item: obj.formData });
    }

    logger.info('Confirm Called', {
      Options: options,
      passedParams: obj,
    });
    const response = await fetch(`${obj.hostPath}/${obj.apiPath}`, options);

    if (!response.ok) {
      return await readFailuresAsync(response);
    }
    return FunctionalResult.Success();
  } catch (error: any) {
    return FunctionalResult.Error(error.message);
  }
};

/**
 * Handles HTTP Post for create purposes and returns a FunctionalResult.
 * @param {CreateAPIModel} the Api Details to pass in
 * @returns {Promise<FunctionalResult<string>>} - A FunctionalResult representing the outcome of the create operation, with the id of the item created
 */
export const createAction = async (
  obj: CreateAPIModel,
): Promise<FunctionalResult<string>> => {
  try {
    const method = obj.method || 'POST';

    const options: RequestInit = {
      method: method,
      headers: obj.userId
        ? {
            'X-User-Identifier': obj.userId,
            'Content-Type': 'application/json',
            [authorizationHeader]: createBearerToken(obj.accessToken),
          }
        : {
            'Content-Type': 'application/json',
            [authorizationHeader]: createBearerToken(obj.accessToken),
          },
    };

    if (method !== 'GET' && method !== 'HEAD') {
      options.body = JSON.stringify({ item: obj.formData });
    }
    logger.info('Create Called', {
      Options: options,
      passedParams: obj,
    });

    const response = await fetch(`${obj.hostPath}/${obj.apiPath}`, options);

    if (!response.ok) {
      return await readFailuresAsync(response);
    }

    const data = await response.text();

    return FunctionalResult.Success(data);
  } catch (error: any) {
    return FunctionalResult.Error(error.message);
  }
};

/**
 * Handles HTTP fetch requests for deletion purposes and returns a FunctionalResult.
 * @param {AuthenticatedAPIModel} the Api Details to pass in
 * @returns {Promise<FunctionalResult<void>>} - A FunctionalResult representing the outcome of the fetch operation.
 */
export const deleteAction = async (
  obj: DeleteAPIModel,
): Promise<FunctionalResult<void>> => {
  try {
    const method = obj.method || 'DELETE';
    const options: RequestInit = {
      method: method,
      headers: obj.userId
        ? {
            'X-User-Identifier': obj.userId,
            'Content-Type': 'application/json',
            [authorizationHeader]: createBearerToken(obj.accessToken),
          }
        : {
            'Content-Type': 'application/json',
            [authorizationHeader]: createBearerToken(obj.accessToken),
          },
    };

    if (obj.formData && method !== 'DELETE' && method !== 'HEAD') {
      options.body = JSON.stringify({ item: obj.formData });
    }


    logger.info('Delete Called', {
      Options: options,
      passedParams: obj,
    });

    const response = await fetch(`${obj.hostPath}/${obj.apiPath}`, options);

    if (!response.ok) {
      return await readFailuresAsync(response);
    }

    return FunctionalResult.Success();
  } catch (error: any) {
    return FunctionalResult.Error(error.message);
  }
};

/**
 * Handles HTTP fetch requests and returns a FunctionalResult.
 * @param {AuthenticatedAPIModel} the Api Details to pass in
 * @param {T} defaultObj - The default object to return if the fetch is successful but no data is returned.
 * @returns {Promise<FunctionalResult<T>>} - A FunctionalResult representing the outcome of the fetch operation.
 */
export const fetchAction = async <T>(
  obj: AuthenticatedAPIModel,
  defaultObj?: T,
): Promise<FunctionalResult<T>> => {
  try {
    const method = obj.method || 'GET';
    const options: RequestInit = {
      method: method,
      headers: obj.userId
        ? {
            'X-User-Identifier': obj.userId,
            'Content-Type': 'application/json',
            [authorizationHeader]: createBearerToken(obj.accessToken),
          }
        : {
            'Content-Type': 'application/json',
            [authorizationHeader]: createBearerToken(obj.accessToken),
          },
    };

    logger.info('Fetch Called', {
      Options: options,
      passedParams: obj,
    });
    const response = await fetch(`${obj.hostPath}/${obj.apiPath}`, options);

    if (!response.ok) {
      return await readFailuresAsync(response);
    }

    if (response.status === 204) {
      return FunctionalResult.Success(defaultObj);
    }

    const data = await response.json();

    return FunctionalResult.Success(data);
  } catch (error: any) {
    return FunctionalResult.Error(error.message);
  }
};

/**
 * Handles HTTP fetch requests when no auth is needed (such as the Enums) and returns a FunctionalResult.
 * @param {AuthenticatedAPIModel} the Api Details to pass in
 * @param {T} defaultObj - The default object to return if the fetch is successful but no data is returned.
 * @returns {Promise<FunctionalResult<T>>} - A FunctionalResult representing the outcome of the fetch operation.
 */
export const fetchActionNoAuth = async <T>(
  obj: BaseAPIModel,
): Promise<FunctionalResult<T>> => {
  try {
    const method = obj.method || 'GET';
    const options: RequestInit = {
      method: method,
      headers: { 'Content-Type': 'application/json' },
    };

    logger.info('Fetch No Auth Called', {
      Options: options,
      passedParams: obj,
    });

    const response = await fetch(`${obj.hostPath}/${obj.apiPath}`, options);

    if (!response.ok) {
      return await readFailuresAsync(response);
    }

    const data = await response.json();

    return FunctionalResult.Success(data);
  } catch (error: any) {
    return FunctionalResult.Error(error.message);
  }
};

/**
 * Handles HTTP fetch requests for file attachments and returns a FunctionalResult.
 * @param {uploadDocumentModel} apiDetails - The API details to pass in.
 * @param {File} file - The file to be uploaded.
 * @returns {Promise<FunctionalResult<string>>} - A FunctionalResult representing the outcome of the upload operation.
 */
export const fetchWithAttachmentAction = async (
  obj: AuthenticatedAPIModel,
): Promise<FunctionalResult<DocumentTypes.returnModel>> => {
  try {
    const method = obj.method || 'GET';
    const headers: HeadersInit = {
      [authorizationHeader]: createBearerToken(obj.accessToken),
    };

    if (obj.userId) {
      headers['X-User-Identifier'] = obj.userId;
    }

    const options: RequestInit = {
      method: method,
      headers: headers,
    };

    logger.info('Fetch with Attachment Called', {
      Options: options,
    });

    const response = await fetch(`${obj.hostPath}/${obj.apiPath}`, options);

    if (!response.ok) {
      return await readFailuresAsync(response);
    }

    if (response.status === 204) {
      return FunctionalResult.Error('No content', 204);
    }

    const blob = await response.blob();
    return FunctionalResult.Success({
      contentType: response.headers.get('Content-Type') || '',
      data: blob,
    });
    return FunctionalResult.Error('No content');
  } catch (error: any) {
    logger.error('Fetch with Attachment failed', {
      message: 'Upload failed',
      error: error,
    });
    return FunctionalResult.Error(error.message);
  }
};

/**
 * Handles HTTP partial update (PATCH ) requests for update purposes and returns a FunctionalResult.
 * @param {CreateAPIModel} the Api Details to pass in
 * @returns {Promise<FunctionalResult<string>>} - A FunctionalResult representing the outcome of the update operation.
 */
export const partialAction = async (
  obj: CreateAPIModel,
): Promise<FunctionalResult<void>> => {
  try {
    const method = obj.method || 'PATCH';
    const options: RequestInit = {
      method: method,
      headers: obj.userId
        ? {
            'X-User-Identifier': obj.userId,
            'Content-Type': 'application/json',
            [authorizationHeader]: createBearerToken(obj.accessToken),
          }
        : {
            'Content-Type': 'application/json',
            [authorizationHeader]: createBearerToken(obj.accessToken),
          },
    };

    if (obj.formData === undefined || obj.formData === null) {
      return FunctionalResult.Error('No data to patch');
    }
    options.body = JSON.stringify({ item: obj.formData });

    logger.info('Patch Called', {
      Options: options,
      passedParams: obj,
    });

    const response = await fetch(`${obj.hostPath}/${obj.apiPath}`, options);

    if (!response.ok) {
      return await readFailuresAsync(response);
    }

    return FunctionalResult.Success();
  } catch (error: any) {
    return FunctionalResult.Error(error.message);
  }
};

/**
 * Handles HTTP PUT requests for update purposes and returns a FunctionalResult.
 * @param {CreateAPIModel} the Api Details to pass in
 * @returns {Promise<FunctionalResult<string>>} - A FunctionalResult representing the outcome of the update operation.
 */
export const updateAction = async (
  obj: updateAPIModel,
): Promise<FunctionalResult<string>> => {
  try {
    const method = obj.method || 'PUT';
    const options: RequestInit = {
      method: method,
      headers: obj.userId
        ? {
            'X-User-Identifier': obj.userId,
            'Content-Type': 'application/json',
            [authorizationHeader]: createBearerToken(obj.accessToken),
          }
        : {
            'Content-Type': 'application/json',
            [authorizationHeader]: createBearerToken(obj.accessToken),
          },
    };

    if (method !== 'GET' && method !== 'HEAD' && obj.formData) {
      options.body = JSON.stringify({ item: obj.formData });
    }

    logger.info('Updated Called', {
      Options: options,
      passedParams: obj,
    });

    const response = await fetch(`${obj.hostPath}/${obj.apiPath}`, options);

    if (!response.ok) {
      return await readFailuresAsync(response);
    }

    const data = await response.text();

    return FunctionalResult.Success(data);
  } catch (error: any) {
    return FunctionalResult.Error(error.message);
  }
};

/**
 *  Handles HTTP Post requests for upload document purposes and returns a FunctionalResult.
 * @param apiDetails {UploadDocumentModel} the Api Details to pass in
 * @returns {Promise<FunctionalResult<void>>} - A FunctionalResult representing the outcome of the update operation.
 */
export const uploadFileAction = async (
  apiDetails: UploadDocumentModel,
): Promise<FunctionalResult<string>> => {
  const formData = new FormData();

  Object.entries(apiDetails.data).forEach(([key, value]) => {
    if (value) {
      formData.append(key, value);
    }
  });

  try {
    const method = apiDetails.method || 'POST';
    const headers: HeadersInit = {
      [authorizationHeader]: createBearerToken(apiDetails.accessToken),
    };

    if (apiDetails.userId) {
      headers['X-User-Identifier'] = apiDetails.userId;
    }

    const options: RequestInit = {
      method: method,
      headers: headers,
      body: formData,
    };

    logger.info('Upload File Called', {
      message: 'Create Called',
      Options: options,
      passedParams: formData,
    });

    const response = await fetch(
      `${apiDetails.hostPath}/${apiDetails.apiPath}`,
      options,
    );

    if (!response.ok) {
      return await readFailuresAsync(response);
    }

    const data = await response.text();
    return FunctionalResult.Success(data);
  } catch (error: any) {
    return FunctionalResult.Error(error.message);
  }
};

/**
 * Tries to read the body for the failures
 * @param {Response} Response - The response object.
 * @returns A detailed Error Functional response
 */
const readFailuresAsync = async <T>(
  response: Response,
): Promise<FunctionalResult<T>> => {
  try {
    if (response.status === 404) {
      return FunctionalResult.Error('Not found', 404);
    }
    const json = await response.json();

    if (response.status === 500 || response.status === 400) {
      return FunctionalResult.Error(
        json.correlationId,
        json.error === undefined || json.error === null // We cant just check for falsely as enums start at 0
          ? response.status
          : json.error,
      );
    } else {
      return FunctionalResult.Error(json.correlationId, response.status);
    }
  } catch (error: any) {
    return FunctionalResult.Error(
      `Request failed with status ${response.status}`,
    );
  }
};
