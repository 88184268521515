import { IntlShape } from 'react-intl';
import {
  KeyValuePairBase,
  ProcessingTextItem,
  ProcessingTextItemAsHtml,
} from '../../sysObjects/common.types';
import { getLocalDateString, getLocalTimeString } from './DateHelpers';
import { ReactElement } from 'react';
import ReactDOMServer from 'react-dom/server'

namespace TextProcessing {

  export const TextProcessor = (
    intl: IntlShape,
    text: string,
    items: KeyValuePairBase<string, ProcessingTextItem>[]
  ): string => {
    if (items == null || items.length === 0) {
      return text;
    }
    let processedText = text;

    items.forEach((item) => {
      const placeholder = `{${item.key}}`;
      const value = ProcessValue(item, intl);
      processedText = processedText.replace(new RegExp(placeholder, 'g'), value);
    });

    return processedText;
  };

  export const TextProcessorAsHtml = (
    intl: IntlShape,
    text: string,
    items: KeyValuePairBase<string, ProcessingTextItemAsHtml>[],
    dateIcon: ReactElement | null = null
  ): string => {
    if (!items || items.length === 0) {
      return text;
    }

    let processedText = text;

    items.forEach((item, index) => {
      const placeholder = `{${item.key}}`;
      if (!text.includes(placeholder)) {
        return;
      }
      const value = ProcessValue(item, intl);

      let returnValue = '';
      if ((item.value.type === 'time' ||
        item.value.type === 'short-date' ||
        item.value.type === 'long-date') &&
        dateIcon) {
        returnValue += `${ReactDOMServer.renderToString(dateIcon)}`;
      }

      returnValue += `<span class="${item.value.spanClass}">${value}</span>`;
      processedText = processedText.replace(new RegExp(placeholder, 'g'), returnValue);
    });

    return processedText;
  };

  const ProcessValue = (item: KeyValuePairBase<string, ProcessingTextItem>, intl: IntlShape): string => {
    let value = item.value.replacementString;

    switch (item.value.type) {
      case 'long-date':
        value = getLocalDateString(value, intl, 'MMMM');
        break;
      case 'short-date':
        value = getLocalDateString(value, intl, 'MM');
        break;
      case 'time':
        value = getLocalTimeString(value, intl);
        break;
    }
    return value;
  };
};

export default TextProcessing;
