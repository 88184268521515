import React, {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import FormControllerContainer from '../formControlContainer/FormControlContainer';
import FormSearchableDropDownTypes from './FormSearchableDropDown.types';
import { ControlState } from '../../../../sysObjects/common.types';
import { FormControlRef } from '../formControlContainer/FormControlContainer.types';
import './FormSearchableDropDown.css';

const FormSearchableDropDown: ForwardRefRenderFunction<
  FormControlRef,
  FormSearchableDropDownTypes.Props
> = (props, ref) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedItem, setSelectedItem] =
    useState<FormSearchableDropDownTypes.SearchableItem | null>(null);
  const [errorString, setErrorString] = useState<string>('');
  const [currentBoxState, setBoxState] = useState<ControlState>('neutral');
  const [disableDefault, setDisable] = useState<boolean>(false);
  const [edited, setEdited] = useState<boolean>(false);
  const dropdownContainerRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    dropdownContainerRef.current?.style.setProperty('max-height', (props.maxItems ?? 5) * 50 + 'px');
  }, [searchQuery]);

  useEffect(() => {
    if(!edited !== false){
      return;
    }
    validateValue();
  }, [selectedItem]);

  useEffect(() => {
    setDisable(!props.requiredDetails ? false : true);
    if (props.value !== undefined && props.value !== null) {
      setSelectedItem(props.items.find((i) => i.key === props.value) || null);
    }
  }, [props.value, props.requiredDetails, props.items]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.readonly) {
      return;
    }

    let updatedSearchText = e.target.value;

    if (props.onChange) {
      props.onChange({
        id: props.id,
        value: '',
        fieldId: props.fieldId,
      });
    }
    validateValue();
    setEdited(true);
    setSearchQuery(updatedSearchText || '');
    setSelectedItem(null);
    dropdownContainerRef.current?.style.setProperty('max-height', (props.maxItems ?? 5) * 50 + 'px');
  };

  const handleItemClick = (
    item: FormSearchableDropDownTypes.SearchableItem
  ) => {
    if (props.readonly) {
      return;
    }
    let updatedValue = item.key;

    setErrorString(
      props.requiredDetails ? props.requiredDetails.message || '' : ''
    );

    if (props.onChange) {
      props.onChange({
        id: props.id,
        value: updatedValue,
        fieldId: props.fieldId,
      });
    }

    setEdited(true);
    setSelectedItem(item);
    setSearchQuery('');
  };

  const highlightText = (text: string, highlight: string) => {
    if (!highlight) return text;

    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);

    return (
      <>
        {parts.map((part, index) => {
          const isHighlight = regex.test(part);

          // Replace leading and trailing spaces with non-breaking spaces
          const formattedPart = part
            .replace(/^\s+/, (match) => match.replace(/ /g, '\u00A0'))
            .replace(/\s+$/, (match) => match.replace(/ /g, '\u00A0'));

          return isHighlight ? (
            <span key={index} className="highlight">
              {formattedPart}
            </span>
          ) : (
            formattedPart
          );
        })}
      </>
    );
  };

  const filteredItems = props.items.filter((item) =>
    item.value.toLowerCase().includes(searchQuery.trim().toLowerCase())
  ).slice(0, props.maxItems ?? 5);

  const validateValue = () => {
    if (props !== undefined && props.readonly) {
      return true;
    }
    if (props.hidden !== undefined && props.hidden) {
      return true;
    }
    if(props.OverRideValidation){
      return props.OverRideValidation;
    };

    if (props.requiredDetails) {
      if (
        !props.value ||
        (typeof props.value === 'string' && !props.value.trim())
      ) {
        setErrorString(props.requiredDetails.message || '');
        setBoxState('negative');
        return false;
      }
      setBoxState('positive');
    }

    setErrorString('');
    setBoxState('positive');
    return true;
  };

  useImperativeHandle(ref, () => ({
    triggerValidation: () => validateValue(),
    reset: () => setBoxState('neutral'),
  }));

  return (
    <FormControllerContainer
      id={props.id}
      boxState={currentBoxState}
      label={props.label}
      errorMessage={errorString}
      helpMessage={props.helpMessage}
      requiredText={props.requiredDetails?.formLabel}
      hidden={props.hidden}
      displayMode={props.displayMode}
      readonly={props.readonly}
    >
      <div className="dropdown-container">
        <input
        className='Text-Understated'
          type="text"
          value={selectedItem?.key ?? ''}
          id={props.id}
          data-testid={`FormSearchableDropDown-${props.id}-hidden-input`}
          readOnly={true}
          hidden={true}
        />
        <input
          type="text"
          value={selectedItem?.value ?? searchQuery}
          onChange={handleSearchChange}
          placeholder={props.defaultText}
          onBlur={() => validateValue()}
          className="search-input"
          id={`FormSearchableDropDown-${props.id}`}
          data-testid={`FormSearchableDropDown-${props.id}`}
          autoComplete='off'
        />
        {searchQuery && (
          <ul className="dropdown-list fill-content"  ref={dropdownContainerRef}>
            {filteredItems.map((item) => (
              <li
                data-testid={`result_${item.key}`}
                key={item.key}
                onClick={() => handleItemClick(item)}
                className="dropdown-item Text-Understated"
              >
                <div className="value-container Text-Understated">
                  {highlightText(item.value, searchQuery.trim().toLowerCase())}
                </div>
                {item.children}
              </li>
            ))}
          </ul>
        )}
      </div>
    </FormControllerContainer>
  );
};

export default React.forwardRef(FormSearchableDropDown);
