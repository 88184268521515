import React, { FC, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import actions from '../../../systemUtils/userUtils/InternalUserActions';
import { useNavigate } from 'react-router-dom';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import { useMsal } from '@azure/msal-react';
import InternalUsers_IndexTypes from './InternalUsersIndex.types';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import {
  getHeadersAsync,
  LocalEnumerations,
} from '../../../systemUtils/common/CommonHelpers';
import InternalUser_Types from '../../../sysObjects/apiModels/InternalUser.types';

import './InternalUsersIndex.css';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import {
  ColDetails,
  colSearchDetails,
  ControlState,
} from '../../../sysObjects/common.types';
import ListTable from '../../../systemComponents/sharedControls/tables/listTable/ListTable';
import ListTableRow from '../../../systemComponents/sharedControls/tables/listTable/listTableRow/ListTableRow';
import StatusLabel from '../../../systemComponents/sharedControls/reusableBlocks/statusLabel/StatusLabel';
import ButtonBox from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';
import ButtonBoxTypes from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox.types';
import ListTableSearch from '../../../systemComponents/sharedControls/tables/listTableSearch/ListTableSearch';
import {
  filterTable,
  sortTable,
} from '../../../systemUtils/common/SortSearchHelpers';
import StandardHeader from '../../../systemComponents/sharedControls/tables/listTable/headers/standard/StandardHeader';
import SortableHeader from '../../../systemComponents/sharedControls/tables/listTable/headers/sortable/SortableHeader';

const InternalUsersIndex: FC = () => {
  const fieldsToSearch: (keyof InternalUsers_IndexTypes.rowData)[] = [
    'fullName',
    'name',
    'surname',
    'jobTitle',
    'primaryEmail',
  ];

  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const context = useContext(CommonPageContext);
  const { userSettings } = useUserSettingsContext();
  const { userClaims } = useContext(UserClaimsContext);
  const { instance } = useMsal();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(true);

  const [sortColumnDetails, setSortColumnDetails] = useState<colSearchDetails>({
    field: 'fullName',
    searchDirection: 'asc',
    searchType: 'string',
  });
  const [intUsers, setIntUsers] = useState<InternalUsers_IndexTypes.rowData[]>(
    []
  );

  const [showTextSearch, setShowTextSearch] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const DetermineUserButtons = (
    user: InternalUser_Types.ByRoleObj,
    checked?: boolean
  ): ButtonBoxTypes.Button[] => {
    let buttons: ButtonBoxTypes.Button[] = [];

    if (user.status !== LocalEnumerations.UserStatuses.Disabled) {
      buttons.push({
        id: `edit-${user.id}`,
        label: locales.statusButtons.edit,
        controlState: 'positive',
        onClick: () => {
          navigate(
            `/team/accounts/edit/${locales.roles[user.role]}/${user.id}`
          );
        },
      });
    } else {
      buttons.push({
        id: `changeState-${user.id}`,
        controlState: 'positive',
        label: locales.statusButtons.enable,
        isChecked: checked,
        onClick: () => {
          updateStatus(user, LocalEnumerations.BasicEntityStatus.Active);
        },
      });
    }
    if (user.status !== LocalEnumerations.UserStatuses.Active) {
      return buttons;
    }

    buttons.push({
      id: `changeState-${user.id}`,
      controlState: 'warning',
      label: locales.statusButtons.disable,
      isChecked: checked,
      onClick: () => {
        setIntUsers((prevUsers) =>
          prevUsers.map((prevUser) =>
            prevUser.id === user.id
              ? {
                  ...prevUser,
                  disabledChecked: !prevUser.disabledChecked,
                }
              : prevUser
          )
        );
      },
    });

    return buttons;
  };

  const updateStatus = async (
    user: InternalUser_Types.ByRoleObj,
    state: number
  ) => {
    actions
      .setUserStateAsync(
        await getHeadersAsync(userClaims, instance),
        user.id,
        state
      )
      .then((result) => {
        if (result.isFailure) {
          showMessage(locales.ApiResponses.editState, 'negative');
          return;
        }
        showMessage(
          `${user.name} ${user.surname} ${locales.ApiResponses.stateChanged}`,
          'positive'
        );
        setIsLoading(true);
        setReload(true);
      });
  };

  const loadDataAsync = async () => {
    actions
      .queryUsersAsync(await getHeadersAsync(userClaims, instance), {
        status: [
          LocalEnumerations.UserStatuses.Active,
          LocalEnumerations.UserStatuses.Invited,
          LocalEnumerations.UserStatuses.Disabled,
        ],
      })
      .then((result) => {
        setIsLoading(false);
        if (result.isFailure || !result.result) {
          showMessage(locales.ApiResponses.loadingFailed, 'negative', '/');
          return;
        }

        setIntUsers(
          result.result.map((usr) => {
            return {
              ...usr,
              fullName: `${usr.name} ${usr.surname}`,
              isExpanded: false,
              roleLabel: locales.roles[usr.role],
              buttonsExpanded: false,
              disabledChecked: false,
              userState: locales.statuses[usr.status || 0],
            };
          })
        );
      });
  };

  useEffect(() => {
    if (!reload) {
      return;
    }

    context?.handleCrumbUpdate(locales.breadcrumbs);

    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      navigate('/');
      return;
    }

    setReload(false);
    loadDataAsync();
  }, [reload]);

  useEffect(() => {
    setIntUsers((prevUsers) => {
      return prevUsers.map((prevUser) => {
        return {
          ...prevUser,
          isExpanded: false,
          buttonsExpanded: false,
        };
      });
    });
  }, [searchString]);

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <ListTable
        className="Internal-Users-Table"
        isLoading={isLoading}
        labels={{
          tableName: locales.tableHeading,
          of: locales.of,
          size: locales.size,
          emptyMessage: locales.emptyMessage,
        }}
        search={{
          isVisible: showTextSearch,
          onStateChange: (isExpanded: boolean) => setShowTextSearch(isExpanded),
        }}
        key="fk"
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        rows={sortTable(
          filterTable(intUsers, searchString, fieldsToSearch),
          sortColumnDetails.field as keyof InternalUsers_IndexTypes.rowData,
          sortColumnDetails.searchDirection === 'none'
            ? 'asc'
            : sortColumnDetails.searchDirection,
          sortColumnDetails.searchType
        ).map((intUser) => {
          return (
            <ListTableRow
              className="Internal-Users-Row"
              id={`user-${intUser.id}`}
              key={`user-${intUser.id}`}
              isExpanded={intUser.isExpanded}
              isButtonRowExpanded={intUser.buttonsExpanded}
              events={{
                OnExpandChange: (isExpanded: boolean) => {
                  setIntUsers((prevUsers) =>
                    prevUsers.map((prevUser) =>
                      prevUser.id === intUser.id
                        ? {
                            ...prevUser,
                            isExpanded,
                            isButtonRowExpanded:
                              isExpanded === true
                                ? false
                                : prevUser.buttonsExpanded,
                            disabledChecked:
                              isExpanded === true
                                ? false
                                : prevUser.disabledChecked,
                          }
                        : {
                            ...prevUser,
                            isExpanded: false,
                            buttonsExpanded: false,
                            disabledChecked: false,
                          }
                    )
                  );
                },
                OnButtonVisibilityChange: (buttonsExpanded: boolean) => {
                  setIntUsers((prevUsers) =>
                    prevUsers.map((prevUser) =>
                      prevUser.id === intUser.id
                        ? { ...prevUser, buttonsExpanded }
                        : {
                            ...prevUser,
                            isExpanded: false,
                            buttonsExpanded: false,
                            disabledChecked: false,
                          }
                    )
                  );
                },
              }}
            >
              <ListTableRow.Collapsed>
                <div>{`${intUser.name} ${intUser.surname}`}</div>
                <div>{intUser.roleLabel}</div>
                <div>
                  <StatusLabel
                    label={intUser.userState}
                    renderAs="span"
                    key={`status-${intUser.id}`}
                    status={
                      (intUser.status || 0) === 0
                        ? 'neutral'
                        : (intUser.status || 0) === 1
                        ? 'positive'
                        : 'negative'
                    }
                  />
                </div>
              </ListTableRow.Collapsed>
              <ListTableRow.Expanded>
                <div>{locales.name}</div>
                <div>{`${intUser.fullName}`}</div>
                <div>{locales.role}</div>
                <div>{intUser.roleLabel}</div>
                <div>{locales.jobTitle}</div>
                <div>{intUser.jobTitle}</div>
                <div>{locales.email}</div>
                <div>{intUser.primaryEmail}</div>
                <div>{locales.tel}</div>
                <div>{intUser.primaryTelephoneNumber}</div>
                <div>{locales.actions}</div>
                <div className="Button-Box-Expand-Container">
                  <ButtonBox
                    id={`Button-Box-${intUser.id}-expanded`}
                    displayBorder={true}
                    buttons={DetermineUserButtons(
                      intUser,
                      intUser.disabledChecked
                    )}
                    className="Button-Box-Expand"
                  />
                  {intUser.disabledChecked && (
                    <ButtonBox
                      id={`confirm-user-${intUser.id}-render`}
                      key={`confirm-user-${intUser.id}-render`}
                      className="Confirm-User-Buttons"
                      displayBorder={true}
                      buttons={[
                        {
                          id: `confirm-user-${intUser.id}-yes`,
                          controlState: 'negative',
                          label: locales.statusButtons.confirm,
                          onClick: () => {
                            updateStatus(
                              intUser,
                              LocalEnumerations.BasicEntityStatus.Deactivated
                            );
                          },
                        },
                        {
                          id: `confirm-user-${intUser.id}-no`,
                          controlState: 'positive',
                          label: locales.statusButtons.cancel,
                          onClick: () => {
                            setIntUsers((prevUsers) =>
                              prevUsers.map((prevUser) =>
                                prevUser.id === intUser.id
                                  ? {
                                      ...prevUser,
                                      disabledChecked: false,
                                      buttonsExpanded: false,
                                    }
                                  : prevUser
                              )
                            );
                          },
                        },
                      ]}
                    />
                  )}
                </div>
              </ListTableRow.Expanded>
              <ListTableRow.ButtonRow
                isExpanded={intUser.buttonsExpanded}
                actionLabel={locales.actions}
                id={intUser.id}
              >
                <ButtonBox
                  buttons={DetermineUserButtons(
                    intUser,
                    intUser.disabledChecked
                  )}
                  displayBorder={false}
                  id={`user-${intUser.id}`}
                  key={`user-${intUser.id}`}
                />
                {intUser.disabledChecked && (
                  <ButtonBox
                    id={`confirm-user-${intUser.id}-render`}
                    key={`confirm-user-${intUser.id}-render`}
                    className="Confirm-User-Buttons"
                    displayBorder={false}
                    buttons={[
                      {
                        id: `confirm-user-${intUser.id}-yes`,
                        controlState: 'negative',
                        label: locales.statusButtons.confirm,
                        onClick: () => {
                          updateStatus(
                            intUser,
                            LocalEnumerations.BasicEntityStatus.Deactivated
                          );
                        },
                      },
                      {
                        id: `confirm-user-${intUser.id}-no`,
                        controlState: 'positive',
                        label: locales.statusButtons.cancel,
                        onClick: () => {
                          setIntUsers((prevUsers) =>
                            prevUsers.map((prevUser) =>
                              prevUser.id === intUser.id
                                ? {
                                    ...prevUser,
                                    disabledChecked: false,
                                    buttonsExpanded: false,
                                  }
                                : prevUser
                            )
                          );
                        },
                      },
                    ]}
                  />
                )}
              </ListTableRow.ButtonRow>
            </ListTableRow>
          );
        })}
      >
        <ListTable.Headers>
          <div className="Heading Text-Regular" />
          {locales.cols.map((col: ColDetails, index: number) => {
            return col.style === 'sortable' ? (
              <SortableHeader
                key={`${col.id}-${index}`}
                id={col.id}
                name={col.name}
                fieldName={col.field}
                searchType={col.searchType}
                sortDirection={
                  col.field === sortColumnDetails.field
                    ? sortColumnDetails.searchDirection
                    : 'none'
                }
                onSortSelected={(name, searchType, sortDirection) => {
                  setSortColumnDetails({
                    field: name,
                    searchDirection: sortDirection,
                    searchType: searchType as
                      | 'string'
                      | 'number'
                      | 'boolean'
                      | 'date',
                  });
                }}
              />
            ) : (
              <StandardHeader
                id={col.id}
                name={col.name}
                key={`${col.id}-${index}`}
              />
            );
          })}
          <div className="Heading Text-Regular" />
        </ListTable.Headers>
        <ListTable.SearchOptions>
          {showTextSearch && (
            <ListTableSearch
              Labels={{ searchButton: locales.search }}
              onSearch={(message: string) => setSearchString(message)}
            />
          )}
        </ListTable.SearchOptions>
      </ListTable>
      <div className="Button-Container">
        <InformationButton
          buttonDetails={{
            itemKey: 'button-create-admin',
            label: locales.createAdmin.Button,
            mode: 'positive',
            clickEvent: () => navigate('/team/accounts/create/admin'),
          }}
        >
          {locales.createAdmin.Summary}
        </InformationButton>
        <InformationButton
          buttonDetails={{
            itemKey: 'button-create-case',
            label: locales.createCase.Button,
            mode: 'positive',
            clickEvent: () => navigate('/team/accounts/create/case'),
          }}
        >
          {locales.createCase.Summary}
        </InformationButton>
        <InformationButton
          buttonDetails={{
            itemKey: 'button-create-assessor',
            label: locales.createAssessor.Button,
            mode: 'positive',
            clickEvent: () => navigate('/team/accounts/create/assessor'),
          }}
        >
          {locales.createAssessor.Summary}
        </InformationButton>
      </div>
    </div>
  );
};

export default InternalUsersIndex;
