import React, { FC } from 'react';
import  InformationButtonTypes  from './InformationButton.types';
import Button from '../button/Button';
import './InformationButton.css';
import StatusBlock from '../../reusableBlocks/statusBlock/StatusBlock';

const InformationButton: FC<InformationButtonTypes.Props> = (props) => {
  const renderState = () => {
    return (
      <div>
        <label htmlFor="info" className='Info-Icon Text-Understated'>
          <StatusBlock
            boxSize="small"
            boxState={!props.state || !props.state.boxState ? 'positive' : props.state.boxState}
            id="info"
            content={props.state!.content}
          />
          {[props.state!.message]}
        </label>
      </div>
    );
  };

  return (
    <div className="information-button-container">
      <div className="information-button-header">
        <Button
          itemKey={props.buttonDetails.itemKey}
          ariaDescribedBy={props.buttonDetails.ariaDescribedBy}
          ariaLabel={props.buttonDetails.ariaLabel}
          clickEvent={props.buttonDetails.clickEvent}
          disabled={props.buttonDetails.disabled}
          label={props.buttonDetails.label}
          mode={props.buttonDetails.mode}
          testId={props.buttonDetails.testId}
          useCooldown={props.buttonDetails.useCooldown}
        >
          {props.buttonDetails.children}
        </Button>
        {props.state ? renderState() : null}
      </div>
      <div className="information-button-body Text-Regular">
        {props.children}
      </div>
    </div>
  );
};

export default InformationButton;
