import React, { FC } from 'react';
import StatusBlock from '../../reusableBlocks/statusBlock/StatusBlock';
import { SvgAdd, SvgOpen } from '../../svg';
import ExpanderTypes from './Expander.types';
import './Expander.css';

const Expander: FC<ExpanderTypes.Props> = (props) => {
  return (
    <StatusBlock
      onclick={() => props.eventHandler.onClick()}
      boxSize="medium"
      id={props.id}
      boxState="positive"
      showIcon={false}
      allowHover={true}
      className={`badgeIcon${props.className ? ` ${props.className}` : ''}`}
      content={
        props.usePlusIcon === true  ? (
          <SvgAdd
            width={30}
            height={30}
            className={`expander-content ${props.isExpanded ? 'expanded' : ''}`}
            testId={`${props.isExpanded! ? 'expanded' : 'collapsed'}`}
          />
        ) : (
          <SvgOpen
            width={30}
            height={30}
            className={`expander-content ${props.isExpanded ? 'expanded' : ''}`}
            testId={`${props.isExpanded! ? 'expanded' : 'collapsed'}`}
          />
        )
      }
    />
  );
};

export default Expander;
