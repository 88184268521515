import React, { FC, useEffect } from 'react';
import ListTable from '../../../../sharedControls/tables/listTable/ListTable';
import ContactTabTypes from './ContactsTab.types';
import StandardHeader from '../../../../sharedControls/tables/listTable/headers/standard/StandardHeader';
import AddCaseContact from '../../caseContact/addCaseContact/AddCaseContact';
import ListTableRow from '../../../../sharedControls/tables/listTable/listTableRow/ListTableRow';
import StatusBlock from '../../../../sharedControls/reusableBlocks/statusBlock/StatusBlock';
import ButtonBox from '../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';

import './ContactsTab.css';
import { filterTable } from '../../../../../systemUtils/common/SortSearchHelpers';
import ListTableSearch from '../../../../sharedControls/tables/listTableSearch/ListTableSearch';
import SideBar from '../../../../sharedControls/reusableBlocks/sideBar/SideBar';
const ContactsTab: FC<ContactTabTypes.ContactTabProps> = (props) => {
  const fieldsToSearch: (keyof ContactTabTypes.ContactDetails)[] = [
    'name',
    'relationshipToCustomer',
    'emailAddress',
    'telephoneNumber',
  ];

  const [contacts, setContacts] = React.useState<
    ContactTabTypes.ContactDetails[]
  >([]);

  const [reload, setReload] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [searchString, setSearchString] = React.useState<string>('');
  const [showTextSearch, setShowTextSearch] = React.useState<boolean>(false);

  useEffect(() => {
    if (reload) {
      props.eventHandlers.onReload().then((result) => {
        if (result.isSuccess) {
          setContacts(
            result.result?.map((contact, index) => {
              return {
                id: contact.id ?? index.toString(),
                name: contact.name,
                relationshipToCustomer: contact.relationshipToCustomer,
                emailAddress: contact.emailAddress,
                telephoneNumber: contact.telephoneNumber,
                isExpanded: false,
              };
            }) || []
          );
          setLoading(false);
          setReload(false);
        }
      });
    }
  }, [reload]);

  useEffect(() => {
    setContacts((prevItems) => {
      return prevItems.map((prevItem) => {
        return {
          ...prevItem,
          isExpanded: false,
        };
      });
    });
  }, [searchString]);

  return (
    <ListTable
      isLoading={loading}
      className="Contacts-Table"
      labels={{
        of: props.labels.table.of,
        size: props.labels.table.size,
        tableName: props.labels.table.tableName,
        emptyMessage: props.labels.table.emptyMessage,
      }}
      search={{
        isVisible: showTextSearch,
        onStateChange: (isExpanded: boolean) => setShowTextSearch(isExpanded),
      }}
      pagingDetails={props.pagingDetails}
      rows={filterTable(contacts, searchString, fieldsToSearch).map(
        (contact) => {
          return (
            <ListTableRow
              className="Contacts-Table-Row"
              key={`contact-${contact.id}`}
              events={{
                OnExpandChange(state, id) {
                  const newContacts = [...contacts];
                  contacts.forEach((c) => {
                    c.isExpanded = c.id === id ? state : false;
                  });
                  setContacts(newContacts);
                },
                OnButtonVisibilityChange: () => {},
              }}
              id={`${contact.id}`}
              isExpanded={contact.isExpanded}
              isButtonRowExpanded={false}
            >
              <ListTableRow.Collapsed>
              <div className="Contact-Title">
                  <StatusBlock
                    boxSize="small"
                    boxState="locked"
                    showIcon={false}
                    allowHover={false}
                    className="Status-User-Int"
                    content={`${contact.name.split(' ')[0].substring(0, 1)}${
                      contact.name.split(' ').length > 1
                        ? contact.name.split(' ')[1].substring(0, 1)
                        : ''
                    }`}
                  />
                  <span>{contact.name}</span>
                </div>
                <div>{contact.relationshipToCustomer}</div>
                <div>{contact.telephoneNumber}</div>
                <div>{contact.emailAddress}</div>

              </ListTableRow.Collapsed>
              <ListTableRow.Expanded>
                <div className="Contact-Title">
                  <StatusBlock
                    boxSize="small"
                    boxState="locked"
                    showIcon={false}
                    allowHover={false}
                    className="Status-User-Int"
                    content={`${contact.name.split(' ')[0].substring(0, 1)}${
                      contact.name.split(' ').length > 1
                        ? contact.name.split(' ')[1].substring(0, 1)
                        : ''
                    }`}
                  />
                  <span>{contact.name}</span>
                </div>
                <div className="Details">
                  <span>{props.labels.contactRow.name}</span>
                </div>
                <div className="Details">
                  <SideBar
                    FieldKey={`SideBar_Name_${contact.id}`}
                    boxState="neutral"
                    includeInnerBar={false}
                  />
                  <span>{contact.name}</span>
                </div>
                <div className="Details">
                  <span>{props.labels.contactRow.relationship}</span>
                </div>
                <div className="Details">
                  <SideBar
                    FieldKey={`SideBar_RelationshipToCustomer_${contact.id}`}
                    boxState="neutral"
                    includeInnerBar={false}
                  />
                  <span>{contact.relationshipToCustomer}</span>
                </div>
                <div className="Details">
                  <span>{props.labels.contactRow.email}</span>
                </div>
                <div className="Details">
                  <SideBar
                    FieldKey={`SideBar_Email_${contact.id}`}
                    boxState="neutral"
                    includeInnerBar={false}
                  />
                  <span>{contact.emailAddress}</span>
                </div>
                <div className="Details">
                  <span>{props.labels.contactRow.phone}</span>
                </div>
                <div className="Details">
                  <SideBar
                    FieldKey={`SideBar_Phone_${contact.id}`}
                    boxState="neutral"
                    includeInnerBar={false}
                  />
                  <span>{contact.telephoneNumber}</span>
                </div>
                <div className="Details">
                  <span>{props.labels.contactRow.actions}</span>
                </div>
                <div>
                  <ButtonBox
                    id={`contact-${contact.id}-remove`}
                    displayBorder={true}
                    key={`contact-${contact.id}-remove`}
                    buttons={[
                      {
                        label: props.labels.contactRow.remove,
                        onClick: () => {
                          props.eventHandlers
                            .onRemove(contact.id, contact.name)
                            .then((result) => {
                              if (result.isSuccess) {
                                setReload(true);
                                setLoading(true);
                              }
                            });
                        },
                        controlState: 'negative',
                        id: `contact-${contact.id}-remove-button`,
                      },
                    ]}
                  />
                </div>
              </ListTableRow.Expanded>
            </ListTableRow>
          );
        }
      )}
      defaultItem={
        <AddCaseContact
          labels={props.labels.addContact}
          eventHandlers={{
            onAdd(
              contactName,
              contactRelationship,
              contactEmail,
              contactTelephone
            ) {
              return props.eventHandlers
                .onAdd(
                  contactName,
                  contactRelationship,
                  contactEmail,
                  contactTelephone
                )
                .then((result) => {
                  if (result.isSuccess) {
                    setReload(true);
                    setLoading(true);
                  }
                });
            },
          }}
        />
      }
    >
      <ListTable.Headers>
        <div />
        {props.labels.table.headers.map((header, index) => (
          <StandardHeader
            id={`col-Contact-${index}`}
            name={header}
            key={`col-Contact-${index}`}
          />
        ))}
      </ListTable.Headers>
      <ListTable.SearchOptions>
        {showTextSearch && (
          <ListTableSearch
            Labels={{ searchButton: props.labels.search }}
            onSearch={(message: string) => setSearchString(message)}
          />
        )}
      </ListTable.SearchOptions>
    </ListTable>
  );
};

export default ContactsTab;
