import React, { useState } from 'react';

import './AddOutComes.css';
import Expander from '../../../../sharedControls/general/expander/Expander';
import AddOutComes_Types from './AddOutComes.types';
import FormDropDown from '../../../../sharedControls/formControls/formDropDown/FormDropDown';
import ButtonBox from '../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';
import SideBar from '../../../../sharedControls/reusableBlocks/sideBar/SideBar';
import CaseUtils from '../../../../../systemUtils/case/caseUtils';
import CaseOutcome_Types from '../../../../../sysObjects/apiModels/CaseOutcome.types';
import {
  FormControlOnChangeData,
  FormControlRef,
} from '../../../../sharedControls/formControls/formControlContainer/FormControlContainer.types';
import FormDate from '../../../../sharedControls/formControls/formDate/FormDate';
import StatusBlock from '../../../../sharedControls/reusableBlocks/statusBlock/StatusBlock';
import CloseBox from '../../../../sharedControls/targetedControls/closeBox/CloseBox';
import { getInitails } from '../../../../../systemUtils/common/CommonHelpers';
import FormTextArea from '../../../../sharedControls/formControls/formTextArea/FormTextArea';
const AddOutComes: React.FC<AddOutComes_Types.Props> = (props) => {
  const formRefs = [
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
  ];

  const [isExpanded, setExpanded] = useState(false);
  const [formData, setFormData] = useState<CaseOutcome_Types.BaseOutcome>(
    CaseUtils.createEmptyAddDiagnosesOutObject()
  );

  return (
    <div className="AddOutComes">
      <Expander
        id={`AddNote_Status`}
        key={`AddNote_Status`}
        className="Expander-Item"
        isExpanded={isExpanded}
        eventHandler={{ onClick: () => setExpanded(!isExpanded) }}
      />
      {!isExpanded && (
        <div className="Action-Title Text-Regular">
          <span className="addLabel">{props.labels.header}</span>
        </div>
      )}
      {isExpanded && (
        <div className="Action-Content">
          <FormDropDown
            displayMode="Column"
            fieldId="diagnosedCondition"
            items={props.conditions}
            id="diagnosedCondition"
            ref={formRefs[0]}
            value={formData.diagnosedCondition}
            label={props.labels.diagnosisCondition}
            defaultText={props.labels.defaultText}
            requiredDetails={{
              formLabel: '',
              message: '',
            }}
            onChange={(
              targetDetails: FormControlOnChangeData<
                string | number | string[] | null
              >
            ) => {
                setFormData({
                ...formData,
                diagnosedCondition: parseInt(targetDetails.value as string, 10),
                });
            }}
          />
          <FormDate
            datePartLabels={props.labels.dateParts}
            fieldId="dateDiagnosed"
            id="dateDiagnosed"
            ref={formRefs[1]}
            label={props.labels.diagnosisDate}
            onChange={(targetDetails) => {
              if (targetDetails.value === null) return;
              setFormData({
                ...formData,
                dateDiagnosed: targetDetails.value.toISOString(),
              });
            }}
            requiredDetails={{
              formLabel: '',
              message: '',
            }}
            value={new Date(formData.dateDiagnosed)}
            displayMode="Column"
            invalidDateText={props.labels.invalidDate}
          />
          <FormDropDown
            displayMode="Column"
            fieldId="diagnosedById"
            items={props.assessors}
            id="diagnosedById"
            ref={formRefs[2]}
            requiredDetails={{
              formLabel: '',
              message: '',
            }}
            defaultText={props.labels.defaultText}
            value={formData.diagnosedById}
            label={props.labels.diagnosisByLabel}
            onChange={(
              targetDetails: FormControlOnChangeData<
                string | number | string[] | null
              >
            ) => {
              setFormData({
                ...formData,
                diagnosedById: targetDetails.value as string,
              });
            }}
          />
          <FormTextArea
            displayMode="Column"
            fieldId="notes"
            id="notes"
            label={props.labels.noteLabel}
            value={formData.notes}
            onChange={(targetDetails) => {
              setFormData({
                ...formData,
                notes: targetDetails.value as string,
              });
            }}
          />
          <div className="Text-Strong Label-Holder">
            <span>{props.labels.addBy}</span>
          </div>
          <div className="Status-Span-Two">
            <SideBar
              FieldKey="diagnoses-SideBar"
              boxState="neutral"
              includeInnerBar={false}
            ></SideBar>

            <StatusBlock
              boxSize="small"
              boxState="locked"
              showIcon={false}
              allowHover={false}
              className="Status-User-Int"
              content={getInitails(props.userName)}
            />
            <span> {props.userName}</span>
          </div>
          <div className="Text-Strong Label-Holder">
            <span>{props.labels.actionLabel}</span>
          </div>
          <ButtonBox
            id="AddOutCome"
            key="AddOutCome"
            className="AddOutCome"
            displayBorder={true}
            buttons={[
              {
                id: 'AddOutCome',
                controlState: 'positive',
                label: props.labels.addLabel,
                onClick: () => {
                  const validationResults = formRefs.map((innerRef) =>
                    innerRef.current?.triggerValidation()
                  );
                  if (!validationResults.every((result) => result === true)) {
                    return;
                  }
                  props.eventsHandler.onAddOutCome(formData).then((rst) => {
                    if (rst.isFailure) {
                      return;
                    }
                    setExpanded(false);
                    setFormData(
                      CaseUtils.createEmptyAddDiagnosesOutObject()
                    );
                  });
                },
              },
              {
                id: 'CancelOutCome',
                controlState: 'negative',
                label: props.labels.cancelLabel,
                onClick: () => {
                  setExpanded(false);
                  setFormData(
                    CaseUtils.createEmptyAddDiagnosesOutObject()
                  );
                },
              },
            ]}
          />
          <div className="Close-Col ">
            <CloseBox onClick={() => setExpanded(false)} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AddOutComes;
