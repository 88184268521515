import React, { FC, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import actions from '../../../systemUtils/pricing/PricingActions';
import { useNavigate } from 'react-router-dom';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import { useMsal } from '@azure/msal-react';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import Enumerations, {
  getHeadersAsync,
  getServerErrors,
} from '../../../systemUtils/common/CommonHelpers';
import {
  ColDetails,
  colSearchDetails,
  ControlState,
} from '../../../sysObjects/common.types';
import ListTable from '../../../systemComponents/sharedControls/tables/listTable/ListTable';
import ListTableRow from '../../../systemComponents/sharedControls/tables/listTable/listTableRow/ListTableRow';
import PricingDefinition_Types from '../../../sysObjects/apiModels/PricingDefinition.types';
import PricingDetails_Types from '../../../systemComponents/targetedPageControls/pricing/index/Details/PricingDetails.types';
import StatusLabel from '../../../systemComponents/sharedControls/reusableBlocks/statusLabel/StatusLabel';
import PricingDetails from '../../../systemComponents/targetedPageControls/pricing/index/Details/PricingDetails';

import './PricingDefinitionDetailsIndex.css';
import { FunctionalResult } from '../../../sysObjects/FunctionalResult';
import ButtonBox from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';
import ListTableSearch from '../../../systemComponents/sharedControls/tables/listTableSearch/ListTableSearch';
import {
  filterTable,
  sortTable,
} from '../../../systemUtils/common/SortSearchHelpers';
import SortableHeader from '../../../systemComponents/sharedControls/tables/listTable/headers/sortable/SortableHeader';
import StandardHeader from '../../../systemComponents/sharedControls/tables/listTable/headers/standard/StandardHeader';

const PricingDefinition_Index: FC = () => {
  const [sortColumnDetails, setSortColumnDetails] = useState<colSearchDetails>({
    field: 'name',
    searchDirection: 'asc',
    searchType: 'string',
  });
  const fieldsToSearch: (keyof PricingDetails_Types.pricingControlData_WithStates)[] =
    ['name', 'description'];
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const context = useContext(CommonPageContext);
  const { userClaims } = useContext(UserClaimsContext);
  const { userSettings } = useUserSettingsContext();
  const { instance } = useMsal();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pricings, setPricings] = useState<
    PricingDetails_Types.pricingControlData_WithStates[]
  >([]);
  const currencies = Enumerations.getSupportedCurrencies(intl.locale).map(
    (currency) => {
      return { key: currency.code, value: currency.description };
    }
  );

  const [showTextSearch, setShowTextSearch] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');

  const saveRecord = async (
    data: PricingDefinition_Types.listItem
  ): Promise<FunctionalResult<void>> => {
    return actions
      .savePricingDefinitionAsync(
        {
          name: data.name,
          description: data.description,
          currency: data.currency,
          status: 1,
        },
        await getHeadersAsync(userClaims, instance),
        data.id
      )
      .then((result) => {
        if (result.isFailure) {
          showMessage(getServerErrors(locales, result.errorCode), 'negative');
          return FunctionalResult.Error('');
        }
        showMessage(
          `${data.name}: - ${locales.ApiResponses.saveSuccess}`,
          'positive',
          '/pricing'
        );

        setPricings((prevPricings) =>
          prevPricings.map((pricing) =>
            pricing.id === data.id
              ? { ...pricing, ...data, isReadOnly: true }
              : pricing
          )
        );
        return FunctionalResult.Success();
      });
  };

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const loadDataAsync = async () => {
    actions
      .findActivePricingDefinitionsAsync(
        null,
        await getHeadersAsync(userClaims, instance)
      )
      .then((result) => {
        if (result.isFailure || !result.result) {
          showMessage(locales.ApiResponses.loadingFailed, 'negative', '/');
          return;
        }

        setPricings(
          result.result!.map((pricing) => {
            return {
              id: pricing.id,
              name: pricing.name,
              description: pricing.description,
              currency: pricing.currency,
              status: pricing.status,
              isExpanded: false,
              isReadOnly: true,
              isButtonRowExpanded: false,
            };
          })
        );
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      showMessage(locales.ApiResponses.permissionError, 'negative', '/');
      return;
    }
    context?.handleCrumbUpdate(locales.breadcrumbs);
    loadDataAsync();
  }, []);

  useEffect(() => {
    setPricings((prevItems) => {
      return prevItems.map((prevItem) => {
        return {
          ...prevItem,
          isExpanded: false,
          buttonsExpanded: false,
        };
      });
    });
  }, [searchString]);

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <ListTable
        isLoading={false}
        search={{
          isVisible: showTextSearch,
          onStateChange: (isExpanded: boolean) => setShowTextSearch(isExpanded),
        }}
        className="Pricing-List-Index"
        labels={{
          of: locales.of,
          size: locales.size,
          tableName: locales.tableName,
          emptyMessage: locales.emptyMessage,
        }}
        pagingDetails={{
          pageSizes: userSettings.pageSizes,
          currentPageSize: userSettings.startingPageSize,
        }}
        rows={sortTable(
          filterTable(pricings, searchString, fieldsToSearch),
          sortColumnDetails.field as keyof PricingDetails_Types.pricingControlData_WithStates,
          sortColumnDetails.searchDirection === 'none'
            ? 'asc'
            : sortColumnDetails.searchDirection,
          sortColumnDetails.searchType
        ).map((pricing) => {
          return (
            <ListTableRow
              className="Pricing-List-Item"
              isExpanded={pricing.isExpanded}
              isButtonRowExpanded={pricing.isButtonRowExpanded}
              id={pricing.id}
              key={pricing.id}
              events={{
                OnExpandChange: (isExpanded: boolean, id: string) => {
                  setPricings((prevPricings) =>
                    prevPricings.map((pricing) =>
                      pricing.id === id
                        ? {
                            ...pricing,
                            isExpanded,
                            isButtonRowExpanded:
                              isExpanded === true
                                ? false
                                : pricing.isButtonRowExpanded,
                          }
                        : {
                            ...pricing,
                            isExpanded: false,
                            isButtonRowExpanded: false,
                          }
                    )
                  );
                },
                OnButtonVisibilityChange: (
                  isButtonRowExpanded: boolean,
                  id: string
                ) => {
                  setPricings((prevPricings) =>
                    prevPricings.map((pricing) =>
                      pricing.id === id
                        ? { ...pricing, isButtonRowExpanded }
                        : {
                            ...pricing,
                            isExpanded: false,
                            isButtonRowExpanded: false,
                          }
                    )
                  );
                },
              }}
            >
              <ListTableRow.Expanded>
                <PricingDetails
                  pricingDefinition={pricing}
                  labels={{
                    actionLabel: locales.actionLabel,
                    description: locales.description,
                    name: locales.name,
                    required: locales.required,
                    requiredMessage: locales.requiredMessage,
                    currency: locales.currency,
                    defaultCurrency: locales.defaultCurrency,
                    editPriceButton: locales.editPriceButton,
                    editButton: locales.editButton,
                    cancelButton: locales.cancelButton,
                    saveButton: locales.saveButton,
                  }}
                  events={{
                    OnSave: saveRecord,
                  }}
                  supportedCurrencies={currencies}
                />
              </ListTableRow.Expanded>
              <ListTableRow.Collapsed>
                <div>{pricing.name}</div>
                <div>
                  <StatusLabel
                    renderAs="span"
                    label={pricing.currency}
                    status="neutral"
                  />
                </div>
                <div>{pricing.description}</div>
              </ListTableRow.Collapsed>
              <ListTableRow.ButtonRow
                isExpanded={pricing.isButtonRowExpanded}
                actionLabel={locales.actionLabel}
                id={pricing.id}
              >
                <ButtonBox
                  id={'RowButtons'}
                  buttons={[
                    {
                      id: `pricingActions-ButtonRow-Edit-Details_${pricing.id}`,
                      controlState: 'positive',
                      label: locales.editButton,
                      onClick: () => {
                        setPricings((prevPricings) =>
                          prevPricings.map((LocalPricing) =>
                            LocalPricing.id === pricing.id
                              ? {
                                  ...LocalPricing,
                                  isReadOnly: false,
                                  isExpanded: true,
                                  isButtonRowExpanded: false,
                                }
                              : {
                                  ...LocalPricing,
                                  isReadOnly: false,
                                  isExpanded: false,
                                  isButtonRowExpanded: false,
                                }
                          )
                        );
                      },
                    },
                    {
                      id: `pricingActions-ButtonRow-Edit-Prices${pricing.id}`,
                      controlState: 'positive',
                      label: locales.editPriceButton,
                      onClick: () => navigate(`/pricing/edit/${pricing.id}`),
                    }
                  ]}
                  displayBorder={false}
                />
              </ListTableRow.ButtonRow>
            </ListTableRow>
          );
        })}
      >
        <ListTable.SearchOptions>
          {showTextSearch && (
            <ListTableSearch
              Labels={{ searchButton: locales.search }}
              onSearch={(message: string) => setSearchString(message)}
            />
          )}
        </ListTable.SearchOptions>
        <ListTable.Headers>
          <div className="Heading Text-Regular" />
          {locales.cols.map((col: ColDetails, index: number) => {
            return col.style === 'sortable' ? (
              <SortableHeader
              key={`${col.id}_${index}`}
                id={col.id}
                name={col.name}
                fieldName={col.field}
                searchType={col.searchType}
                sortDirection={
                  col.field === sortColumnDetails.field
                    ? sortColumnDetails.searchDirection
                    : 'none'
                }
                onSortSelected={(name, searchType, sortDirection) => {
                  setSortColumnDetails({
                    field: name,
                    searchDirection: sortDirection,
                    searchType: searchType as
                      | 'string'
                      | 'number'
                      | 'boolean'
                      | 'date',
                  });
                }}
              />
            ) : (
              <StandardHeader id={col.id} name={col.name} />
            );
          })}
          <div className="Heading Text-Regular" />
        </ListTable.Headers>
      </ListTable>
      <div className="Button-Container">
        <InformationButton
          buttonDetails={{
            itemKey: 'infoButtonBilling',
            label: locales.billingButton,
            mode: 'positive',
            clickEvent: () => navigate('/pricing/create'),
          }}
        >
          {locales.billingSummary}
        </InformationButton>
      </div>
    </div>
  );
};

export default PricingDefinition_Index;
