import React, { useState, useRef } from 'react';
import FormTextCapture from '../../../../sharedControls/formControls/formTextCapture/FormTextCapture';
import { FormControlRef } from '../../../../sharedControls/formControls/formControlContainer/FormControlContainer.types';
import ButtonBox from '../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';
import { AddCaseContactProps } from './AddCaseContact.types';
import Expander from '../../../../sharedControls/general/expander/Expander';
import './AddCaseContact.css';

const AddCaseContact: React.FC<AddCaseContactProps> = (props) => {
  const [isExpanded, setExpanded] = useState(false);
  const [contactName, setContactName] = React.useState<string>('');
  const [contactEmail, setContactEmail] = React.useState<string>('');
  const [contactRelationship, setContactRelationship] =
    React.useState<string>('');
  const [contactTelephone, setContactTelephone] = React.useState<string>('');

  const formRefs = [
    useRef<FormControlRef>(null),
    useRef<FormControlRef>(null),
    useRef<FormControlRef>(null),
    useRef<FormControlRef>(null),
  ];

  const resetAddContactForm = () => {
    setContactName('');
    setContactRelationship('');
    setContactEmail('');
    setContactTelephone('');
  };

  return (
    <div className="Contact-Adder">
      <Expander
        id={`AddContact_Status`}
        key={`AddContact_Status`}
        isExpanded={isExpanded}
        usePlusIcon={true}
        eventHandler={{
          onClick: () => {
            setExpanded(!isExpanded);
            if (!isExpanded) {
              resetAddContactForm();
            }
          },
        }}
      />
      <div className="Action-Title Text-Regular">
        <span className="addLabel">{props.labels.header}</span>
      </div>
      {isExpanded && (
        <div className="Contact-Add-Details">
          <FormTextCapture
            textInputType={'text'}
            fieldId="contactName"
            id="contactName"
            label={props.labels.contactName}
            value={contactName}
            maxLength={250}
            ref={formRefs[0]}
            requiredDetails={{
              formLabel: '',
              message: `${props.labels.contactName} ${props.labels.requiredField}`,
            }}
            onChange={(e) => {
              setContactName(e.value as string);
            }}
            displayMode={'Column'}
          />
          <FormTextCapture
            textInputType={'text'}
            fieldId="contactRelationship"
            id="contactRelationship"
            label={props.labels.contactRelationship}
            value={contactRelationship}
            maxLength={250}
            ref={formRefs[1]}
            requiredDetails={{
              formLabel: '',
              message: `${props.labels.contactRelationship} ${props.labels.requiredField}`,
            }}
            onChange={(e) => {
              setContactRelationship(e.value as string);
            }}
            displayMode={'Column'}
          />
          <FormTextCapture
            textInputType={'email'}
            fieldId="contactEmail"
            id="contactEmail"
            label={props.labels.contactEmail}
            value={contactEmail}
            maxLength={100}
            ref={formRefs[2]}
            onChange={(e) => {
              setContactEmail(e.value as string);
            }}
            displayMode={'Column'}
          />
          <FormTextCapture
            textInputType={'tel'}
            fieldId="contactTelephone"
            id="contactTelephone"
            label={props.labels.contactTelephone}
            value={contactTelephone}
            maxLength={100}
            ref={formRefs[3]}
            onChange={(e) => {
              setContactTelephone(e.value as string);
            }}
            displayMode={'Column'}
          />
          <ButtonBox
            key={`case_contact_buttons_Add`}
            id={`case_contact_buttons_Add`}
            className="Add-Case-Contact-Buttons"
            displayBorder={true}
            buttons={[
              {
                id: `AddContact`,
                label: props.labels.addButton,
                controlState: 'positive',
                onClick: () => {
                  const validationResults = formRefs.map((ref) =>
                    ref.current?.triggerValidation()
                  );
                  const allValid = validationResults.every(
                    (result) => result === true
                  );
                  if (!allValid) {
                    return;
                  }

                  props.eventHandlers.onAdd(
                    contactName,
                    contactRelationship,
                    contactEmail,
                    contactTelephone
                  );
                },
              },
              {
                id: `Cancel`,
                label: props.labels.cancelButton,
                controlState: 'negative',
                onClick: () => {
                  resetAddContactForm();
                  setExpanded(false);
                },
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};
export default AddCaseContact;
