import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import { useIntl } from 'react-intl';
import { useMsal } from '@azure/msal-react';
import ExternalUsersActions from '../../../systemUtils/userUtils/ExternalUserActions';
import Enumerations, {
  getHeadersAsync,
} from '../../../systemUtils/common/CommonHelpers';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import CustomerTypes from '../../../sysObjects/apiModels/Customer.types';
import PillControl from '../../../systemComponents/sharedControls/formControls/pillControl/PillControl';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import {
  FormControlOnChangeData,
  FormControlRef,
} from '../../../systemComponents/sharedControls/formControls/formControlContainer/FormControlContainer.types';
import { ColDetails, ControlState, KeyValuePair } from '../../../sysObjects/common.types';
import FormTextCapture from '../../../systemComponents/sharedControls/formControls/formTextCapture/FormTextCapture';
import FormDropDown from '../../../systemComponents/sharedControls/formControls/formDropDown/FormDropDown';
import FormTextArea from '../../../systemComponents/sharedControls/formControls/formTextArea/FormTextArea';
import DiagnosisRecordActions from '../../../systemUtils/userUtils/DiagosisRecordActions';
import { DiagnosisRecordRowTypes } from '../../../systemComponents/targetedPageControls/diagnosisRecords/DiagnosisRecordRow/DiagnosisRecordRow.types';
import DateHelpers from '../../../systemUtils/common/DateHelpers';
import DiagnosisRecordList from '../../../systemComponents/targetedPageControls/diagnosisRecords/DiagnosisRecordList';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';

const CreateUpdateProfile = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const { instance } = useMsal();
  const { userClaims } = React.useContext(UserClaimsContext);
  const { id, accountId } = useParams();
  const navigate = useNavigate();
  const context = React.useContext(CommonPageContext);
  const { userSettings } = useUserSettingsContext();

  const formRefs = [
    [useRef<FormControlRef>(null), useRef<FormControlRef>(null)],
    [
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
    ],
    [
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
    ],
    [],
  ];
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isRefreshing, setIsRefreshing] = React.useState<boolean>(false);

  const [refreshRequired, setRefreshRequired] = React.useState<boolean>(true);
  const [formData, setFormData] = React.useState<CustomerTypes.Profile>(
    ExternalUsersActions.createCustomerProfile(),
  );
  const diagnosedConditions = Enumerations.getRelatedConditions(intl.locale);
  const diagnosisSources = Enumerations.getDiagnosisSources(intl.locale);
  const pronouns = Enumerations.getPronouns(intl.locale);
  const employmentTypes = Enumerations.getEmploymentTypes(intl.locale);

  const [diagnosisRecords, setDiagnosisRecords] = React.useState<DiagnosisRecordRowTypes.Item[]>([]);

  const [pageStates, setPages] = useState<KeyValuePair<string>[]>([
    {
      key: locales.controls.pillControl.tabs[0],
      value: 'neutral',
    },
    {
      key: locales.controls.pillControl.tabs[1],
      value: 'neutral',
    },
    {
      key: locales.controls.pillControl.tabs[2],
      value: 'neutral',
    },
    {
      key: locales.controls.pillControl.tabs[3],
      value: 'neutral',
    }
  ]);

  const mapRows = (retrievedRecord: CustomerTypes.RetrievedDiagnosisRecord): DiagnosisRecordRowTypes.Item => {
    return {
      id: retrievedRecord.id,
      diagnosisDate: DateHelpers.getLocalDateString(retrievedRecord.dateDiagnosed!, intl, "MMMM"),
      diagnosisSource: diagnosisSources.find((source) => source.key === retrievedRecord.diagnosisSource)?.value ?? '',
      addedByName: `${retrievedRecord.createdBySummary.name} ${retrievedRecord.createdBySummary.surname}`,
      addedByInitials: `${retrievedRecord.createdBySummary.name.charAt(0)}${retrievedRecord.createdBySummary.surname.charAt(0)}`,
      diagnosedCondition: diagnosedConditions.find((condition) => condition.key === retrievedRecord.diagnosedCondition)?.value ?? '',
      expanded: false,
      notes: retrievedRecord.notes,
      onRemove: async () => {
        DiagnosisRecordActions.removeDiagnosisRecordAsync(
          retrievedRecord.id,
          await getHeadersAsync(userClaims, instance),
        ).then((result) => {
          if (result.isFailure) {
            showMessage(locales.ApiResponses.errors.serverErrors.default, 'negative');
            return;
          }
          showMessage(
            `${locales.diagnosisRecordListLabels.removeErrorDetails.success}`,
            'positive',
          );
          setDiagnosisRecords(diagnosisRecords.filter((record) => record.id !== retrievedRecord.id));
        });
      },
    } as DiagnosisRecordRowTypes.Item;
  };

  useEffect(() => {
    const loadDataAsync = async () => {
      setIsLoading(true);
      await ExternalUsersActions.getUserAsync(
        await getHeadersAsync(userClaims, instance),
        accountId!,
      )
        .then((accountResponse) => {
          if (accountResponse.isFailure) {
            showMessage(locales.ApiResponses.errors.noCustomerFound, 'negative');
          } else {
            context?.handleCrumbUpdate([
              ...locales.breadcrumbs.common,
              {
                label: `${accountResponse.result!.surname}, ${accountResponse.result!.name}`,
                key: accountId || '',
                link: `/customers/accounts/edit/${locales.roles[accountResponse.result!.role]}/${accountId}`,
              },
              locales.breadcrumbs.profile,
            ]);
          }
          return accountResponse;
        })
        .then(async (accountResponse) => {
          if (accountResponse.isFailure) {
            return;
          }

          if (id === undefined || id === null) {
            setFormData({ ...formData, userAccountId: accountId! });
            return;
          }

          ExternalUsersActions.getCustomerProfileAsync(
            await getHeadersAsync(userClaims, instance),
            accountId!,
          )
            .then(async (profileResponse) => {
              if (profileResponse.isFailure) {
                showMessage(locales.ApiResponses.errors.noProfileFound, 'negative');
                return;
              }
              setFormData(profileResponse.result!);

              return profileResponse;
            })
            .catch((error) => {
              showMessage(locales.ApiResponses.errors.noProfileFound, 'negative');
            });
        });
    };

    if(!refreshRequired){
      return;
    }
    setRefreshRequired(false);

    context?.handleCrumbUpdate([...locales.breadcrumbs.common]);
    if (!UserCore.userIsCaseManagerOrHigher(userClaims!.user!)) {
      showMessage(locales.ApiResponses.errors.noPermission, 'negative', '/');
      return;
    }
    if (accountId === undefined || accountId === null) {
      showMessage(locales.ApiResponses.errors.noAccountId, 'negative', '/customers/accounts');
      return;
    }

    loadDataAsync().then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if(!refreshRequired || !id){
      return;
    }
    setRefreshRequired(false);
    setIsRefreshing(true);
    getHeadersAsync(userClaims, instance).then((headers) => {
      DiagnosisRecordActions.retrieveDiagnosisRecordsAsync(
        accountId!,
        headers,
      ).then((diagnosisResponse) => {
        if(diagnosisResponse.isFailure){
          showMessage(locales.diagnosisRecordListLabels.common.loadingFailed, 'negative');
          return;
        }
  
        setDiagnosisRecords(Array.isArray(diagnosisResponse.result) ? diagnosisResponse.result.map(mapRows) : []);
        setIsRefreshing(false);
      });
    });
  }, [refreshRequired]);

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const handleFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: result.value,
    }));
  };

  const handleSelectChange = (
    result: FormControlOnChangeData<string | string[] | number | null>,
  ) => {
    if (result.value === null) {
      return;
    }

    if (Array.isArray(result.value)) {
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]:
        typeof result.value === 'number'
          ? result.value
          : parseInt(result.value! as string, 10),
    }));
  };

  const handleFormChangeEducation = (
    result: FormControlOnChangeData<string | number | null>,
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      customerInEducation: {
        ...prevData.customerInEducation!,
        [result.fieldId]: result.value,
      },
    }));
  };

  const pageChange = (oldPage: number) => {
    const validationResults = formRefs[oldPage].map((ref) =>
      ref.current?.triggerValidation(),
    );
    const allValid = validationResults.every((result) => result === true);

    setPages((prevPages) => {
      const updatedPages = [...prevPages];
      updatedPages[oldPage] = {
        ...updatedPages[oldPage],
        value: allValid ? 'positive' : 'negative',
      };
      return updatedPages;
    });
  };

  const saveRecord = async () => {
    let allValid: boolean[] = [];

    formRefs.forEach((ref, i) => {
      const validationResults = ref.map((innerRef) =>
        innerRef.current?.triggerValidation(),
      );
      const tabValid = validationResults.every((result) => result === true);
      allValid.push(tabValid);
      setPages((prevPages) => {
        const updatedPages = [...prevPages];
        updatedPages[i] = {
          ...updatedPages[i],
          value: tabValid ? 'positive' : 'negative',
        };
        return updatedPages;
      });
    });

    const pageValid = allValid.every((isValid) => isValid);
    if (pageValid) {
      ExternalUsersActions.saveCustomerProfileAsync(
        await getHeadersAsync(userClaims, instance),
        formData,
        id,
      ).then((result) => {
        if (result.isFailure) {
          showMessage(locales.ApiResponses.errors.serverErrors.default, 'negative');
          return;
        }
        showMessage(
          `${formData.preferredName}: - ${locales.ApiResponses.success.recordSaved}`,
          'positive',
          '/customers/accounts',
        );
      });
      return;
    }
  };

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <PillControl
        backLabel={locales.controls.pillControl.back.label}
        nextLabel={locales.controls.pillControl.forward.label}
        pageChangeAction={pageChange}
        pages={[
          {
            name: pageStates[0].key,
            mode: pageStates[0].value as ControlState,
            enabled: true,
            orderNo: 1,
            showAsOrdered: true,
            content: (
              <>
                <FormTextCapture
                  displayMode="Box"
                  fieldId="preferredName"
                  ref={formRefs[0][0]}
                  id="preferredName"
                  label={locales.controls.preferredName.label}
                  textInputType="text"
                  value={formData.preferredName}
                  maxLength={250}
                  onChange={handleFormChange}
                />
                <FormDropDown
                  displayMode="Box"
                  fieldId="pronouns"
                  id="pronouns"
                  label={locales.controls.pronouns.label}
                  items={pronouns}
                  value={formData.pronouns}
                  defaultText={locales.common.defaultSelect}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.pronouns.label} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[0][1]}
                  onChange={handleSelectChange}
                />
              </>
            ),
          },
          {
            name: pageStates[1].key,
            mode: pageStates[1].value as ControlState,
            enabled: true,
            orderNo: 3,
            showAsOrdered: true,
            content: (
              <>
                <FormDropDown
                  displayMode="Box"
                  fieldId="employmentType"
                  id="employmentType"
                  label={locales.controls.employmentType.label}
                  items={employmentTypes}
                  value={formData.employmentType}
                  defaultText={locales.common.defaultSelect}
                  ref={formRefs[1][0]}
                  onChange={handleSelectChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.employmentType.label} ${locales.common.requiredMessage}`,
                  }}
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="lengthOfEmployment"
                  ref={formRefs[1][1]}
                  id="lengthOfEmployment"
                  label={locales.controls.lengthOfEmployment.label}
                  textInputType="text"
                  value={formData.lengthOfEmployment}
                  maxLength={250}
                  onChange={handleFormChange}
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="employmentContactName"
                  ref={formRefs[1][2]}
                  id="employmentContactName"
                  label={locales.controls.employmentContactName.label}
                  textInputType="text"
                  value={formData.employmentContactName}
                  maxLength={250}
                  onChange={handleFormChange}
                />
                <FormTextArea
                  displayMode="Box"
                  fieldId="roleAndResponsibilities"
                  ref={formRefs[1][3]}
                  id="roleAndResponsibilities"
                  label={locales.controls.roleAndResponsibilities.label}
                  value={formData.roleAndResponsibilities}
                  maxLength={250}
                  onChange={handleFormChange}
                />
                <FormTextArea
                  displayMode="Box"
                  fieldId="employmentHistory"
                  ref={formRefs[1][4]}
                  id="employmentHistory"
                  label={locales.controls.employmentHistory.label}
                  value={formData.employmentHistory}
                  maxLength={250}
                  onChange={handleFormChange}
                />
              </>
            ),
          },
          {
            name: pageStates[2].key,
            mode: pageStates[2].value as ControlState,
            enabled: true,
            orderNo: 4,
            showAsOrdered: true,
            content: (
              <>
                {formData.customerInEducation === null && (
                  <InformationButton
                    key="informationSave"
                    buttonDetails={{
                      itemKey: 'informationSave',
                      clickEvent: () =>
                        setFormData({
                          ...formData,
                          customerInEducation:
                            ExternalUsersActions.createCustomerInEducation(),
                        }),
                      label: locales.controls.addEducation.label,
                      mode: 'positive',
                      testId: 'addEducation_button',
                    }}
                  >
                    <>{locales.controls.addEducation.summary}</>
                  </InformationButton>
                )}
                <FormTextCapture
                  textInputType="text"
                  displayMode="Box"
                  fieldId="universityCollegeName"
                  ref={formRefs[2][0]}
                  id="universityCollegeName"
                  label={locales.controls.universityCollegeName.label}
                  value={formData.customerInEducation?.universityCollegeName}
                  maxLength={250}
                  onChange={handleFormChangeEducation}
                  hidden={formData.customerInEducation === null}
                />
                <FormTextCapture
                  textInputType="text"
                  displayMode="Box"
                  fieldId="courseName"
                  ref={formRefs[2][1]}
                  id="courseName"
                  label={locales.controls.courseName.label}
                  value={formData.customerInEducation?.courseName}
                  maxLength={250}
                  onChange={handleFormChangeEducation}
                  hidden={formData.customerInEducation === null}
                />
                <FormTextCapture
                  textInputType="text"
                  displayMode="Box"
                  fieldId="lengthOfCourse"
                  ref={formRefs[2][2]}
                  id="lengthOfCourse"
                  label={locales.controls.lengthOfCourse.label}
                  value={formData.customerInEducation?.lengthOfCourse}
                  maxLength={250}
                  onChange={handleFormChangeEducation}
                  hidden={formData.customerInEducation === null}
                />
                <FormTextCapture
                  textInputType="text"
                  displayMode="Box"
                  fieldId="yearOfStudy"
                  ref={formRefs[2][3]}
                  id="yearOfStudy"
                  label={locales.controls.yearOfStudy.label}
                  value={formData.customerInEducation?.yearOfStudy}
                  maxLength={250}
                  onChange={handleFormChangeEducation}
                  hidden={formData.customerInEducation === null}
                />
              </>
            ),
          },
          {
            name: pageStates[3].key,
            mode: pageStates[3].value as ControlState,
            enabled: true,
            orderNo: 2,
            showAsOrdered: true,
            content: (
              <DiagnosisRecordList
                rows={diagnosisRecords}
                cols={[
                  {
                    name: locales.diagnosisRecordListLabels.columns.diagnosedCondition,
                    style: 'sortable',
                    id: 'diagnosedCondition',
                    field: 'diagnosedCondition',
                    searchType: 'string',
                  },
                  {
                    name: locales.diagnosisRecordListLabels.columns.diagnosisDate,
                    style: 'sortable',
                    id: 'diagnosisDate',
                    field: 'diagnosisDate',
                    searchType: 'date',
                  },
                  {
                    name: locales.diagnosisRecordListLabels.columns.diagnosisSource,
                    style: 'sortable',
                    id: 'diagnosisSource',
                    field: 'diagnosisSource',
                    searchType: 'string',
                  },
                ] as ColDetails[]}
                labels={locales.diagnosisRecordListLabels}
                events={{
                  onAddDiagnosisRecord: async (record) => {
                    if(!id){
                      return;
                    }

                    DiagnosisRecordActions.addDiagnosisRecordsAsync(
                      {...record, customerId: id!},
                      await getHeadersAsync(userClaims, instance),
                    ).then((result) => {
                      if (result.isFailure) {
                        showMessage(locales.diagnosisRecordListLabels.addErrorDetails.serverErrors.default, 'negative');
                        return;
                      }
                      showMessage(
                        `${locales.diagnosisRecordListLabels.addErrorDetails.success}`,
                        'positive',
                      );

                      setRefreshRequired(true);
                    });
                  },
                }}
                pagingDetails={{
                  currentPageSize: userSettings.startingPageSize,
                  pageSizes: userSettings.pageSizes,
                }}
                readOnly={false} 
                isLoading={isLoading || isRefreshing} 
                triggerRefresh={
                 () => setRefreshRequired(true)
                }
                enumerations={{
                  relatedConditions: diagnosedConditions,
                  diagnosisSources: diagnosisSources.filter((source) => source.key !== 0),
                }}
              />
            ),
          },
        ]}
      />

      <InformationButton
        key="informationSave"
        buttonDetails={{
          itemKey: 'informationSave',
          clickEvent: saveRecord,
          label: locales.controls.saveInformation.label,
          testId: 'save-customer-profile-button',
          mode: 'positive',
        }}
      >
        <>{locales.controls.saveInformation.summary}</>
      </InformationButton>
    </div>
  );
};

export default CreateUpdateProfile;
