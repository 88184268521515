import { FC, Fragment } from 'react';
import CaseSummaryTypes from './CaseSummary.types';
import './CaseSummary.css';
import ProgressSideBar from '../../../sharedControls/targetedControls/progressSideBar/ProgressSideBar';
import { useIntl } from 'react-intl';
import TextProcessing from '../../../../systemUtils/common/TextProcessing';
import { ControlState, KeyValuePairBase, ProcessingTextItemAsHtml } from '../../../../sysObjects/common.types';
import Enumerations from '../../../../systemUtils/common/CommonHelpers';
import StatusBlock from '../../../sharedControls/reusableBlocks/statusBlock/StatusBlock';
import Bad from '../../../sharedControls/svg/Bad';
import Warning from '../../../sharedControls/svg/Warning';
import PageLoader from '../../../sharedControls/general/loading/pageLoader/PageLoader';
import { FaCircle } from 'react-icons/fa6';
import { SvgClock } from '../../../sharedControls/svg';

const getSoonestDueDate = (
  slas: CaseSummaryTypes.SlaSummary[],
): CaseSummaryTypes.SlaSummary | undefined => {
  return slas
    .filter((sla) => !sla.completedDate && sla.dueDate != null)
    .sort((a, b) => new Date(a.dueDate!).getTime() - new Date(b.dueDate!).getTime())[0];
};

const getDaysUntilDue = (dueDate: number): number => {
  return Math.ceil((dueDate - Date.now()) / 86400000);
};

const clockIcon = <StatusBlock
  boxSize="small"
  boxState={'positive'}
  content={<SvgClock height={20} width={20} fill='white' />}
/>;

const CaseSummary: FC<CaseSummaryTypes.Props> = (props) => {
  const intl = useIntl();
  const caseStatuses = Enumerations.getCaseStatuses(intl.locale);
  const referralReasons = Enumerations.getReferralReasons(intl.locale);
  const serviceStatuses = Enumerations.getServiceStatuses(intl.locale);
  const relatedConditions = Enumerations.getRelatedConditions(intl.locale);
  const diagnosisSources = Enumerations.getDiagnosisSources(intl.locale);

  const variableMap: KeyValuePairBase<
    string,
    ProcessingTextItemAsHtml>[] =
    props.caseSummary ?
      [
        {
          key: 'referrerFullname', value: {
            replacementString: props.caseSummary.referrerFullname,
            type: 'string',
            spanClass: 'Highlighted-Variable Text-Capitalised'
          }
        },
        {
          key: 'referrerAccountName', value: {
            replacementString: props.caseSummary.referrerAccountName,
            type: 'string',
            spanClass: 'Highlighted-Variable Text-Capitalised'
          }
        },
        {
          key: 'referralDateTime', value: {
            replacementString: new Date(props.caseSummary.referralDateTime).toISOString(),
            type: 'short-date',
            spanClass: 'Highlighted-Variable Text-Capitalised'
          }
        },
        {
          key: 'caseStatus', value: {
            replacementString: caseStatuses.filter((status) => status.key === props.caseSummary!.caseStatus)[0].value,
            type: 'string',
            spanClass: 'Highlighted-Variable Text-Capitalised'
          }
        },
        {
          key: 'caseReference', value: {
            replacementString: props.caseSummary.caseReference,
            type: 'string',
            spanClass: 'Highlighted-Variable Text-Capitalised'
          }
        },
        {
          key: 'referrerReference', value: {
            replacementString: props.caseSummary.referrerReference,
            type: 'string',
            spanClass: 'Highlighted-Variable Text-Capitalised'
          }
        },
        {
          key: 'mostRecentCaseNoteContent', value: {
            replacementString: props.caseSummary.mostRecentCaseNoteContent || '',
            type: 'string',
            spanClass: 'Highlighted-Variable Text-Capitalised'
          }
        },
        {
          key: 'referralReason', value: {
            replacementString: props.caseSummary.referralReason !== undefined && props.caseSummary.referralReason !== null ?
              props.caseSummary.referralReason !== 99
                ? referralReasons.filter((reason) => reason.key === props.caseSummary!.referralReason)[0].value || ''
                : props.caseSummary.referralReasonOther || ''
              : '',
            type: 'string',
            spanClass: 'Highlighted-Variable Text-Capitalised'
          }
        },
        {
          key: 'customerFullname', value: {
            replacementString: props.caseSummary.customerFullname,
            type: 'string',
            spanClass: 'Highlighted-Variable Text-Capitalised'
          }
        },
        {
          key: 'latestCostApprovalDate',
          value: {
            replacementString: props.caseSummary.latestCostApprovalDate
              ? new Date(props.caseSummary.latestCostApprovalDate).toISOString()
              : '',
            type: 'short-date',
            spanClass: 'Highlighted-Variable Text-Capitalised'
          }
        },
        {
          key: 'poNumber',
          value: {
            replacementString: props.caseSummary.poNumber || '',
            type: 'string',
            spanClass: 'Highlighted-Variable Text-Capitalised'
          }
        },
        {
          key: 'preRequisitesCompleted',
          value: {
            replacementString: props.caseSummary.preRequisitesCompleted ? new Date(props.caseSummary.preRequisitesCompleted).toISOString() : '',
            type: 'short-date',
            spanClass: 'Highlighted-Variable Text-Capitalised'
          }
        }
      ] : [];

  const renderGlobablSection = (label: string) => TextProcessing.TextProcessorAsHtml(intl, label, variableMap, clockIcon);

  const renderGeneralSection = () => {
    if (!props.caseSummary) {
      return;
    }
    return (
      <div
        className='col list Text-Regular'
        data-testid='general-section'
      >
        <ProgressSideBar
          FieldKey="pro"
          boxState='positive'
          location='top'
        />

        <div className='Summary-List' key='GeneralSection'>
          <div className="Font-Heading">
            {props.labels.generalHeading}
          </div>
          <div
            className='Summary-Item'
            data-testid='referralSummary'
            dangerouslySetInnerHTML={{ __html: renderGlobablSection(props.labels.summaryContent.referralSummary) }}
          />
          <div
            className='Summary-Item'
            data-testid='caseStatus'
            dangerouslySetInnerHTML={{ __html: renderGlobablSection(props.labels.summaryContent.caseStatus) }}
          />
          <div
            className='Summary-Item'
            data-testid='caseReference'
            dangerouslySetInnerHTML={{ __html: renderGlobablSection(props.labels.summaryContent.caseReference) }}
          />
          {props.caseSummary.mostRecentCaseNoteContent && (
            <div className='Recent-Case-Note'>
              <span className="Text-Capitalised Case-Note-Heading">{props.labels.mostRecentCaseNoteHeading}</span>
              <span className="Text-Regular">{props.caseSummary.mostRecentCaseNoteContent}</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const mapSlaWithServiceStatuses = (sla: CaseSummaryTypes.ServiceLevelSlaSummary) => {
    const collection = mapSlaSummary(sla);

    if (!sla.endServiceStatus) {
      return collection;
    }

    collection.push({
      key: 'endServiceStatus',
      value: {
        replacementString: serviceStatuses.filter((status) => status.key === sla.endServiceStatus)[0].value,
        type: 'string',
        spanClass: 'Highlighted-Variable Text-Capitalised'
      }
    } as KeyValuePairBase<string, ProcessingTextItemAsHtml>);

    collection.push({
      key: 'startServiceStatus',
      value: {
        replacementString: serviceStatuses.filter((status) => status.key === sla.startServiceStatus)[0].value,
        type: 'string',
        spanClass: 'Highlighted-Variable Text-Capitalised'
      }
    } as KeyValuePairBase<string, ProcessingTextItemAsHtml>);

    return collection;
  };

  const mapSlaSummary = (sla: CaseSummaryTypes.SlaSummary) => {
    const collection = [
      {
        key: 'slaName',
        value: {
          replacementString: sla.name,
          type: 'string',
          spanClass: 'Highlighted-Variable Text-Capitalised'
        }
      },
      {
        key: 'dueDate',
        value: {
          replacementString: sla.dueDate ? new Date(sla.dueDate!).toISOString() : '',
          type: 'short-date',
          spanClass: 'Highlighted-Variable Text-Capitalised'
        }
      },
      {
        key: 'endStatus',
        value: {
          replacementString: caseStatuses.filter((status) => status.key === sla.endCaseStatus)[0].value,
          type: 'string',
          spanClass: 'Highlighted-Variable Text-Capitalised'
        }
      },
      {
        key: 'startStatus',
        value: {
          replacementString: caseStatuses.filter((status) => status.key === sla.startCaseStatus)[0].value,
          type: 'string',
          spanClass: 'Highlighted-Variable Text-Capitalised'
        }
      }
    ] as KeyValuePairBase<string, ProcessingTextItemAsHtml>[];

    if (sla.completedDate) {
      collection.push({
        key: 'completedDate',
        value: {
          replacementString: new Date(sla.completedDate).toISOString(),
          type: 'short-date',
          spanClass: 'Highlighted-Variable Text-Capitalised'
        }
      });
    }

    return collection;
  };

  const renderSlaSummary = (sla: CaseSummaryTypes.SlaSummary | CaseSummaryTypes.ServiceLevelSlaSummary, key: string) => {
    let cssClasses = ['Summary-Item', 'Sla-Item'];
    let caseSlaClass = '';
    let status = 'neutral';
    let icon = <FaCircle color='var(--System-Colour-Lexxic-Darker-Grey)' />;
    const daysUntilDue = sla.dueDate ? getDaysUntilDue(new Date(sla.dueDate!).getTime()) : 6;
    if (!sla.completedDate && daysUntilDue <= 5) {
      caseSlaClass = daysUntilDue <= 0 ? 'overdue' : 'dueSoon';
      status = daysUntilDue <= 0 ? 'negative' : 'warning';
      cssClasses.push(caseSlaClass);
      icon = daysUntilDue <= 0 ? <Bad fill='white' /> : <Warning fill='var(--System-Colour-Lexxic-Tertiary)' />;
    }

    const html =
      sla.completedDate
        ? TextProcessing.TextProcessorAsHtml(intl, props.labels.summaryContent.slaSummaryCompleted, mapSlaSummary(sla))
        : sla.dueDate
          ? 'startServiceStatus' in sla && (sla as CaseSummaryTypes.ServiceLevelSlaSummary).startServiceStatus !== null
            ? TextProcessing.TextProcessorAsHtml(intl, props.labels.summaryContent.slaSummaryStartedWithServiceStatuses, mapSlaWithServiceStatuses(sla as CaseSummaryTypes.ServiceLevelSlaSummary))
            : TextProcessing.TextProcessorAsHtml(intl, props.labels.summaryContent.slaSummaryStarted, mapSlaSummary(sla))
          : TextProcessing.TextProcessorAsHtml(intl, props.labels.summaryContent.slaSummaryUnstarted, mapSlaSummary(sla));

    return (
      <div className={cssClasses.join(' ')} key={`sla-summary-${key}-container`}>
        <StatusBlock
          boxSize="small"
          boxState={status as ControlState}
          id={`Sla-Icon-${sla.name}`}
          key={`Sla-Icon-${sla.name}`}
          content={icon} />
        <div
          className='Summary-Item'
          data-testid={`sla-summary-${key}`}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    );
  };

  const renderAppointment = (service: CaseSummaryTypes.ServiceSummary, key: string) => {
    if (!service.upcomingAppointment) {
      return null;
    }

    const appointmentMap = [
      {
        "key": "serviceName",
        "value": {
          "replacementString": service.serviceDescriptor,
          "type": "string",
        }
      },
      {
        "key": "appointmentDate",
        "value": {
          "replacementString": new Date(service.upcomingAppointment!.appointmentDateTime).toISOString(),
          "type": "short-date",
        }
      },
      {
        "key": "appointmentTime",
        "value": {
          "replacementString": new Date(service.upcomingAppointment!.appointmentDateTime).toISOString(),
          "type": "time",
        }
      },
      {
        "key": "assessorFullname",
        "value": {
          "replacementString": service.upcomingAppointment!.assessorName,
          "type": "string",
        }
      }
    ] as KeyValuePairBase<string, ProcessingTextItemAsHtml>[];

    return (
      <div
        className='Summary-Item'
        data-testid={`upcoming-appointment-${key}`}
        dangerouslySetInnerHTML={{ __html: TextProcessing.TextProcessorAsHtml(intl, props.labels.summaryContent.appointmentSummary, appointmentMap) }}
      />
    );
  };

  const renderActiveCaseSection = () => {
    if (!props.caseSummary || (!props.caseSummary.caseLevelSlaSummaries.length && !props.caseSummary.services.length)) {
      return;
    }

    let cssList = ['col', 'list', 'Text-Regular'];
    const soonestDueDate = getSoonestDueDate(props.caseSummary.caseLevelSlaSummaries.concat(props.caseSummary.services.map((service) => service.serviceLevelSlaSummaries).flat()));

    let placement = 'none';
    let boxState = 'neutral';

    if (soonestDueDate !== undefined) {
      cssList.push('Sla-States');
      const daysUntilDue = getDaysUntilDue(new Date(soonestDueDate.dueDate!).getTime());
      if (daysUntilDue <= 5) {
        cssList.push(daysUntilDue <= 0 ? 'overdue' : 'dueSoon');
        placement = daysUntilDue <= 0 ? 'bottom' : 'middle';
        boxState = daysUntilDue <= 0 ? 'negative' : 'warning';
      } else {
        cssList.push('dueLater');
        placement = 'top';
        boxState = 'positive';
      }
    }

    return (
      <div
        className={cssList.join(' ')}
        data-testid='active-case-section'
      >
        <ProgressSideBar
          FieldKey="pro"
          boxState={boxState as ControlState}
          location={placement as 'top' | 'middle' | 'bottom'}
        />
        <div className='Summary-List Sla-Section'>
          <div className="Font-Heading">
            {props.labels.activeCaseHeading}
          </div>
          {props.caseSummary.caseLevelSlaSummaries.map((sla, index) => {
            return renderSlaSummary(sla, `case-${index}`);
          })}

          {props.caseSummary.services.concat(props.caseSummary.products).filter(x => x.serviceLevelSlaSummaries.length).map((service, index, array) => {
            return (
              <Fragment key={`service-summary-${index}`}>
                <div className="View-Splitter" />
                <span className="Service-Heading">{service.serviceDescriptor}</span>
                {renderAppointment(service, index.toString())}
                {service.serviceLevelSlaSummaries.map((sla, serviceIndex) => {
                  return renderSlaSummary(sla, `service-${index}-${serviceIndex}`);
                })}
                {service.reportDispatchDate && (
                  <div
                    className='Summary-Item Sla-Item'
                    data-testid={`reportDispatchDate-${index}`}
                    dangerouslySetInnerHTML={{
                      __html: TextProcessing.TextProcessorAsHtml(intl, props.labels.summaryContent.reportDispatchDate, [
                        {
                          key: 'reportDispatchDate',
                          value: {
                            replacementString: service.reportDispatchDate ? new Date(service.reportDispatchDate).toISOString() : '',
                            type: 'short-date'
                          }
                        }])
                    }}
                  />
                )}
                {index === array.length - 1 && <div className="View-Splitter" />}
              </Fragment>
            )
          })}
        </div>
      </div>
    );
  };

  const renderPreCaseSection = () => {
    if (!props.caseSummary) {
      return;
    }
    return (
      <div
        className='col list Text-Regular'
        data-testid='pre-case-section'
      >
        <ProgressSideBar
          FieldKey="pro"
          boxState='positive'
          location='top'
        />
        <div className='Summary-List' key="PreCaseSection">
          <div className="Font-Heading">
            {props.labels.preCaseHeading}
          </div>
          {props.caseSummary.diagnosisRecords.map((diagnosis, index) => {
            return (
              <div
                className='Summary-Item'
                data-testid={`diagnosis-${index}`}
                dangerouslySetInnerHTML={{
                  __html: TextProcessing.TextProcessorAsHtml(
                    intl,
                    props.labels.summaryContent.previousDiagnosisSummary,
                    [
                      {
                        key: 'diagnosedCondition',
                        value: {
                          replacementString: relatedConditions.filter((condition) => condition.key === diagnosis.diagnosedCondition)[0].value,
                          type: 'string',
                          spanClass: 'Highlighted-Variable Text-Capitalised'
                        }
                      },
                      {
                        key: 'dateDiagnosed',
                        value: {
                          replacementString: new Date(diagnosis.dateDiagnosed).toISOString(),
                          type: 'short-date',
                        }
                      },
                      {
                        key: 'diagnosisSource',
                        value: {
                          replacementString: diagnosisSources.filter((source) => source.key === diagnosis.diagnosisSource)[0].value,
                          type: 'string',
                          spanClass: 'Highlighted-Variable Text-Capitalised'
                        }
                      }
                    ],
                    clockIcon)
                }}
              />
            )
          })}

          {props.caseSummary.referralReason !== null && 
            <div
              className='Summary-Item'
              data-testid='referralReason'
              dangerouslySetInnerHTML={{ __html: renderGlobablSection(props.labels.summaryContent.referralReason) }}
            />
          }

          <div
            className='Summary-Item'
            data-testid='referralOverview'
            dangerouslySetInnerHTML={{ __html: renderGlobablSection(props.labels.summaryContent.referralOverview) }}
          />
          <p className='Sub-Heading'>{props.labels.servicesHeading}</p>
          {props.caseSummary.services.length
            ? props.caseSummary.services.map((service, index) => {
              return (
                <div className='Summary-Item Text-Capitalised' key={`service-${index}`}>
                  <StatusBlock
                    boxSize="small"
                    boxState="neutral"
                    id={`Service-Icon-${index}`}
                    content={<FaCircle color='var(--System-Colour-Lexxic-Darker-Grey)' />}
                  />
                  <span>{service.serviceDescriptor}</span>
                </div>
              )
            })
            : (
              <div className='Summary-Item Text-Capitalised'>
                <StatusBlock
                  boxSize="small"
                  boxState="neutral"
                  id={`No-Services-Requested}`}
                  content={<FaCircle color='var(--System-Colour-Lexxic-Darker-Grey)' />}
                />
                {props.labels.noneRequested}
              </div>
            )
          }
          <p className='Sub-Heading'>{props.labels.productsHeading}</p>
          {props.caseSummary.products.length
            ?
            props.caseSummary.products.map((product, index) => {
              return (
                <div className='Summary-Item Text-Capitalised' key={`product-${index}`}>
                  <StatusBlock
                    boxSize="small"
                    boxState="neutral"
                    id={`Service-Icon-${index}`}
                    content={<FaCircle color='var(--System-Colour-Lexxic-Darker-Grey)' />}
                  />
                  <span>{product.serviceDescriptor}</span>
                </div>
              )
            })
            : (
              <div className='Summary-Item Text-Capitalised'>
                <StatusBlock
                  boxSize="small"
                  boxState="neutral"
                  id={`No-Products-Requested}`}
                  content={<FaCircle color='var(--System-Colour-Lexxic-Darker-Grey)' />}
                />
                {props.labels.noneRequested}
              </div>
            )}

          {props.caseSummary.latestCostApprovalDate && (
            <div
              className='Summary-Item'
              data-testid='mostRecentCostApproval'
              dangerouslySetInnerHTML={{ __html: renderGlobablSection(props.labels.summaryContent.mostRecentCostApproval) }}
            />
          )}

          {props.caseSummary.preRequisitesCompleted && (
            <div
              className='Summary-Item'
              data-testid='preRequisitesCompleted'
              dangerouslySetInnerHTML={{ __html: renderGlobablSection(props.labels.summaryContent.preRequisitesCompleted) }}
            />
          )}
        </div>
      </div >
    );
  };

  return props.isLoading
    ? (
      <PageLoader alt={props.labels.loading} />
    )
    : (
      <div className="CaseSummary-Container">
        <div className="Tab-Heading Font-Heading">{props.labels.summaryHeading}</div>
        {renderGeneralSection()}
        {renderActiveCaseSection()}
        {renderPreCaseSection()}
      </div>
    );
};

export default CaseSummary;
