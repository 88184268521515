import React, { FC, useEffect } from 'react';
import Expander from '../../../sharedControls/general/expander/Expander';
import FormDate from '../../../sharedControls/formControls/formDate/FormDate';
import PrepaidBundleTypes from '../../../../sysObjects/apiModels/PrepaidBundle.types';
import AddBundleTypes from './AddBundle.types';
import CloseBox from '../../../sharedControls/targetedControls/closeBox/CloseBox';
import {
  FormControlOnChangeData,
  FormControlRef,
} from '../../../sharedControls/formControls/formControlContainer/FormControlContainer.types';
import FormTextCapture from '../../../sharedControls/formControls/formTextCapture/FormTextCapture';
import FormDropDown from '../../../sharedControls/formControls/formDropDown/FormDropDown';
import SideBar from '../../../sharedControls/reusableBlocks/sideBar/SideBar';
import { OrgActions } from '../../../../systemUtils/organisation/OrganisationActions';
import ButtonBox from '../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';

import './AddBundle.css';
import ServiceDefinition_Types from '../../../../sysObjects/apiModels/ServiceDefinition.types';

const AddBundle: FC<AddBundleTypes.Props> = (props) => {
  const formRefs = [
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
  ];
  const [isExpanded, setExpanded] = React.useState(false);
  const [formData, setFormData] =
    React.useState<PrepaidBundleTypes.PrepaidBundle>();

  const [services, setServices] = React.useState<
    ServiceDefinition_Types.serviceDetailsForAddBuddle[]
  >([]);

  useEffect(() => {
    setFormData(OrgActions.createDefaultBundle(props.billingId ?? ''));
  }, []);

  /**
   * Reset the form to its default state
   */
  const resetAddBundleForm = () => {
    setFormData(OrgActions.createDefaultBundle(props.billingId ?? ''));
  };

  /**
   * Handle the click event for the expander
   */
  const handleExpanderClick = () => {
    const result = !isExpanded;

    setExpanded(result);
    if (!result) {
      resetAddBundleForm();
    }
  };

  /**
   * Render the title of the add bundle control
   * @returns The title of the add bundle control
   */
  const renderTitle = () => (
    <div className="Action-Title">
      <span>{props.labels.addTitleLabel}</span>
    </div>
  );

  /**
   * Handles the event that fires when a value changes
   */
  const handleFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | boolean | Date | undefined | null
    >
  ) => {
    setFormData(
      (prevData) =>
        ({
          ...prevData,
          [result.fieldId]: result.value,
        } as PrepaidBundleTypes.PrepaidBundle)
    );
  };

  return (
    <div className="Add-Bundle">
      <Expander
        id={`AddBundle`}
        key={`AddBundle`}
        isExpanded={isExpanded}
        usePlusIcon={true}
        eventHandler={{
          onClick: handleExpanderClick,
        }}
      />
      {isExpanded && (
        <div className="Expanded">
          {renderTitle()}{' '}
          <FormDropDown
            displayMode="Column"
            fieldId="serviceCategory"
            id="serviceCategory"
            value={formData?.serviceCategory}
            items={props.categories}
            label={props.labels.serviceCategory}
            key="addServiceCat"
            ref={formRefs[0]}
            defaultText={props.labels.default}
            requiredDetails={{
              formLabel: props.labels.common.required,
              message: props.labels.common.requiredMessage,
            }}
            onChange={(details) => {
              const v = props.categories.find(
                (p) => p.key === details.value
              )?.value;

              setFormData(
                (prevData) =>
                  ({
                    ...prevData,
                    serviceCategory: details.value,
                    serviceDefinitionId: undefined,
                  } as PrepaidBundleTypes.PrepaidBundle)
              );
              setServices(
                props.services.filter((service) => service.serviceType === v)
              );
            }}
          />
          <FormDropDown
            items={services.map((service) => ({
              key: service.serviceDefinitionId,
              value: service.name,
            }))}
            displayMode="Column"
            fieldId="serviceDefinitionId"
            id="serviceDefinitionId"
            value={formData?.serviceDefinitionId}
            label={props.labels.service}
            key="serviceDefinitionId"
            defaultText={props.labels.any}
            onChange={handleFormChange}
          />
          <FormTextCapture
            displayMode="Column"
            fieldId="volume"
            id="volume"
            value={formData?.volume?.toString() ?? ''}
            label={props.labels.volumeLabel}
            textInputType="number"
            key="volume"
            onChange={(e) => {
              setFormData(
                (prevData) =>
                  ({
                    ...prevData,
                    volume: parseInt(e.value as any),
                  } as PrepaidBundleTypes.PrepaidBundle)
              );
            }}
            requiredDetails={{
              formLabel: props.labels.common.required,
              message: props.labels.common.requiredMessage,
            }}
            ref={formRefs[1]}
          />
          <FormDate
            displayMode="Column"
            fieldId="expiryDate"
            id="expiryDate"
            value={new Date(formData?.expiryDate ?? '')}
            label={props.labels.expiryDateLabel}
            requiredDetails={{
              formLabel: props.labels.common.required,
              message: props.labels.common.requiredMessage,
            }}
            datePartLabels={props.labels.datePartLabels}
            key="expiryDate"
            onChange={handleFormChange}
            invalidDateText={props.labels.invalidDateText}
            ref={formRefs[2]}
          />
          <FormTextCapture
            displayMode="Column"
            fieldId="reference"
            id="reference"
            value={formData?.reference}
            label={props.labels.referenceLabel}
            textInputType="text"
            key="reference"
            onChange={handleFormChange}
            requiredDetails={{
              formLabel: props.labels.common.required,
              message: props.labels.common.requiredMessage,
            }}
            maxLength={200}
            ref={formRefs[3]}
          />
          <div className="Text-Strong">{props.labels.actionLabel}</div>
          <ButtonBox
            id="AddBundle"
            displayBorder={true}
            className="AddBuble-Buttons"
            buttons={[
              {
                id: 'AddBundle',
                label: props.labels.buttonLabels.add,
                onClick: () => {
                  var result = formRefs
                    .map((ref) => ref.current?.triggerValidation())
                    .every((res) => res === true);
                  if (!result) {
                    return;
                  }
                  const v = props.categories.find(
                    (p) => p.key === formData!.serviceCategory
                  )?.value;
                  
                  const data = {
                    ...formData,
                    serviceDefinitionId:
                      formData?.serviceDefinitionId || undefined,
                      serviceCategory: v 
                  };
                

                  props.eventHandlers
                    .onAddBundle(data as PrepaidBundleTypes.PrepaidBundle)
                    .then((rst) => {
                      if (rst.isSuccess) handleExpanderClick();
                    });
                },
                useCooldown: true,
                controlState: 'positive',
              },
              {
                id: 'Cancel',
                label: props.labels.buttonLabels.cancel,
                onClick: handleExpanderClick,
                controlState: 'negative',
              },
            ]}
          />
          <div className="Close-Box-Col">
            <CloseBox onClick={handleExpanderClick} />
          </div>
        </div>
      )}
      {!isExpanded && renderTitle()}
    </div>
  );
};

export default AddBundle;
