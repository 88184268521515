import React, { FC, useState } from 'react';
import { PillControlProps } from './PillControl.types';
import Button from '../../general/button/Button';
import {
  BsArrowRightSquareFill,
  BsArrowLeftSquareFill,
  BsGear,
} from 'react-icons/bs';

import './PillControl.css';
import StatusBlock from '../../reusableBlocks/statusBlock/StatusBlock';
import InformationButton from '../../general/InformationButton/InformationButton';

const PillControl: FC<PillControlProps> & {
  CenterControls: React.FC<React.PropsWithChildren<{}>>;
  ConfirmControls: React.FC<React.PropsWithChildren<{}>>;
} = (props) => {
  const [activeTab, setActiveTab] = useState(1);

  const centerButtons = React.Children.toArray(props.children).find(
    (child: any) => child.type === CenterControls
  );
  const confirmButtons = React.Children.toArray(props.children).find(
    (child: any) => child.type === ConfirmControls
  );
  const renderButtons = () => {
    return (
      <div
        key="FormContainer_ButtonHolder"
        className="form-Container-button-container"
      >
        {props.pages.length < 2 ? null : props.pages.some(
            (page) =>
              page.orderNo < activeTab && page.enabled && page.showAsOrdered
          ) ? (
            <InformationButton
            key={'infoButton'}
            buttonDetails={{
              itemKey: 'infoButton',
              children: (
              <BsArrowLeftSquareFill className="icon tab-button-icon" />
              ),
              mode: 'positive',
              testId: 'back-button',
              clickEvent: (e) => {
              const previousOrderNo = [...props.pages]
                .reverse()
                .find(
                (page) =>
                  page.orderNo < activeTab &&
                  page.enabled &&
                  page.showAsOrdered
                )?.orderNo;
              if (previousOrderNo) {
                changePage(previousOrderNo);
              }
              },
            }}
            >
            {props.backLabel ?? 'Previous'}
            </InformationButton>
        ) : (
          <div />
        )}
        {centerButtons}
        {props.pages.length < 2 ? null : props.pages.some(
            (page) =>
              page.orderNo > activeTab && page.enabled && page.showAsOrdered
          ) ? (
          <InformationButton
            key="container_forward"
            data-testid="NextButton"
            buttonDetails={{
              itemKey: 'container_forward',
              children: (
                <BsArrowRightSquareFill className="icon tab-button-icon" />
              ),
              mode: 'positive',
              testId: 'next-button',
              clickEvent: (e) => {
                const firstNextOrderNo = props.pages.find(
                  (page) =>
                    page.orderNo > activeTab &&
                    page.enabled &&
                    page.showAsOrdered
                )?.orderNo;
                if (firstNextOrderNo) {
                  changePage(firstNextOrderNo);
                }
              },
            }}
          >
            {props.nextLabel ?? 'Next'}
          </InformationButton>
        ) : (
          <div />
        )}
        {confirmButtons !== undefined && (<><div />{confirmButtons}</> ) }
      </div>
    );
  };

  const changePage = (num: number) => {
    if (props.pageChangeAction) {
      const newPage = props.pages.find((p) => p.orderNo === num)!;
      props.pageChangeAction(
        props.pages.findIndex((p) => p.orderNo === activeTab),
        props.pages.findIndex((p) => p.orderNo === num),
        newPage.pageReference
      );
    }

    if (props.pages.some((p) => p.orderNo == num && p.enabled)) {
      setActiveTab(num);
    }
  };

  const renderTabs = () => {
    return (
      <div className="form-Container-tab-container">
        {props.pages
          .sort((a, b) => {
            if (a.showAsOrdered && !b.showAsOrdered) {
              return -1;
            } else if (!a.showAsOrdered && b.showAsOrdered) {
              return 1;
            } else {
              return a.orderNo - b.orderNo;
            }
          })
          .map((p, i) => {
            return (
              <div
                key={`tab_${i}`}
                className={`form-Container-tab   ${
                  p.orderNo === activeTab ? 'active' : ''
                }${p.enabled ? '' : ' disabled'}`}
                onClick={(e) => {
                  if (!p.enabled) {
                    return;
                  }
                  changePage(p.orderNo);
                }}
                tabIndex={0}
                data-testid={`tab_${i}`}
              >
                <StatusBlock
                  boxSize="small"
                  boxState={p.enabled ? p.mode : 'locked'}
                  id={`tabMainStatus_${i}`}
                  key={`tabMainStatus_${i}`}
                  content={p.enabled ? (p.showAsOrdered ? i + 1 : '') : '-'}
                  showIcon={p.mode !== 'neutral'}
                />
                <label className="tab-label Impact-Regular">{p.name}</label>
              </div>
            );
          })}
      </div>
    );
  };

  return props.pages === null || props.pages === undefined || !props.pages ? (
    <div key="FormContainer_ErrorHolder">Error</div>
  ) : (
    <div className="form-container" key="FormContainer">
      {renderTabs()}
      <div key="FormContainer_Holder" className="form-Container-body-container">
        {!props.pages.length
          ? null
          : props.pages.map((page, i) => {
              return (
                <div
                  key={`${page.name}_${i}`}
                  hidden={activeTab !== page.orderNo}
                >
                  {page.content}
                </div>
              );
            })}
      </div>
      {props.pages.find((p) => p.orderNo === activeTab)?.showAsOrdered === false
        ? null
        : renderButtons()}
    </div>
  );
};

const CenterControls: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  return <div className="PillCenter">{children}</div>;
};
const ConfirmControls: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  return <div className="ConfirmCancel">{children}</div>;
};

PillControl.CenterControls = CenterControls;
PillControl.ConfirmControls = ConfirmControls
export default PillControl;
