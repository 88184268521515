import React, { ReactNode, useContext, useState } from 'react';
import { UserClaimsContext } from '../contexts/UserClaimsContext';
import { LocalEnumerations } from '../../../systemUtils/common/CommonHelpers';

export const RoleFilter: React.FC<{
  children: ReactNode;
  requiredRoles: number[];
}> = ({ children, requiredRoles }) => {
  return IsUserInRole(requiredRoles) ? <> {children} </> : null;
};

export function IsUserInRole(requiredRoles: number[]) {
  const { userClaims } = useContext(UserClaimsContext);

  return userClaims?.user && requiredRoles.includes(userClaims.user.role);
}

export const UserIsInInternalRole = () => {
  const { userClaims } = useContext(UserClaimsContext);

  const rslt =  
    userClaims?.user &&
    [
      LocalEnumerations.Roles.Assessor,
      LocalEnumerations.Roles.AssociateAssessor,
      LocalEnumerations.Roles.CaseManager,
      LocalEnumerations.Roles.SuperUser,
    ].includes(userClaims.user.role)

    if(rslt === true){
      return true;
    }
    return false;
};

