import { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Enumerations, {
  getHeadersAsync
} from '../../../systemUtils/common/CommonHelpers';
import { useNavigate, useParams } from 'react-router-dom';
import { ColDetails, ControlState, KeyValuePair } from '../../../sysObjects/common.types';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import extUserActions from '../../../systemUtils/userUtils/ExternalUserActions';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import { useMsal } from '@azure/msal-react';

import locales from './locales/en-GB.json';
import KeyValueTableTypes from '../../../systemComponents/sharedControls/tables/keyValueTable/KeyValueTable.types';
import DateHelpers from '../../../systemUtils/common/DateHelpers';

import DiagnosisRecordActions from '../../../systemUtils/userUtils/DiagosisRecordActions';
import { DiagnosisRecordRowTypes } from '../../../systemComponents/targetedPageControls/diagnosisRecords/DiagnosisRecordRow/DiagnosisRecordRow.types';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import PillControl from '../../../systemComponents/sharedControls/formControls/pillControl/PillControl';
import KeyValueTable from '../../../systemComponents/sharedControls/tables/keyValueTable/KeyValueTable';
import CustomerTypes from '../../../sysObjects/apiModels/Customer.types';
import DiagnosisRecordList from '../../../systemComponents/targetedPageControls/diagnosisRecords/DiagnosisRecordList';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';

import './ExternalUserView.css';

const ExternalUserView = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const context = useContext(CommonPageContext);
  const { id } = useParams();
  const { userClaims } = useContext(UserClaimsContext);
  const { userSettings } = useUserSettingsContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { instance } = useMsal();
  const diagnosedConditions = Enumerations.getRelatedConditions(intl.locale);
  const diagnosisSources = Enumerations.getDiagnosisSources(intl.locale);
  const isCustomerProfilePopulated = useRef<boolean>(false);

  const queryParams = new URLSearchParams(document.location.search);
  const previousPath = queryParams.get('from') ?? '/cases';

  const [nameTable, setNameTable] = useState<
    KeyValueTableTypes.KeyValueTableGroup[]
  >([]);
  const [personalDetailsTable, setPersonalDetailsTable] = useState<
    KeyValueTableTypes.KeyValueTableGroup[]
  >([]);
  const [contactDetailsTable, setContactDetailsTable] = useState<
    KeyValueTableTypes.KeyValueTableGroup[]
  >([]);
  const [profileNameTable, setProfileNameTable] = useState<
    KeyValueTableTypes.KeyValueTableGroup[]
  >([]);
  const [employmentTable, setEmploymentTable] = useState<
    KeyValueTableTypes.KeyValueTableGroup[]
  >([]);
  const [educationTable, setEducationTable] = useState<
    KeyValueTableTypes.KeyValueTableGroup[]
  >([]);

  const [pageStates, setPages] = useState<KeyValuePair<string>[]>([
    {
      key: locales.title1,
      value: 'neutral',
    },
    {
      key: locales.title2,
      value: 'neutral',
    },
    {
      key: locales.title3,
      value: 'neutral',
    }
  ]);

  const [diagnosisRecords, setDiagnosisRecords] = useState<DiagnosisRecordRowTypes.Item[]>([]);

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const mapRows = (retrievedRecord: CustomerTypes.RetrievedDiagnosisRecord): DiagnosisRecordRowTypes.Item => {
    return {
      id: retrievedRecord.id,
      diagnosisDate: DateHelpers.getLocalDateString(retrievedRecord.dateDiagnosed!, intl, "MMMM"),
      diagnosisSource: diagnosisSources.find((source) => source.key === retrievedRecord.diagnosisSource)?.value ?? '',
      addedByName: `${retrievedRecord.createdBySummary.name} ${retrievedRecord.createdBySummary.surname}`,
      addedByInitials: `${retrievedRecord.createdBySummary.name.charAt(0)}${retrievedRecord.createdBySummary.surname.charAt(0)}`,
      diagnosedCondition: diagnosedConditions.find((condition) => condition.key === retrievedRecord.diagnosedCondition)?.value ?? '',
      expanded: false,
      notes: retrievedRecord.notes,
    } as DiagnosisRecordRowTypes.Item;
  };


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!id) {
        showMessage(
          locales.ApiResponses.idNotSet,
          'negative',
          previousPath
        );
        return;
      }

      try {
        const headers = await getHeadersAsync(userClaims, instance);
        if (headers.isFailure) {
          showMessage(
            locales.ApiResponses.invalidUserDetails,
            'negative',
            previousPath
          );
          return;
        }

        const userResponse = await extUserActions.getUserAsync(headers, id);
        if (userResponse.isFailure) {
          showMessage(
            locales.ApiResponses.errorFetchingUser,
            'negative',
            previousPath
          );
          return;
        }

        const customerProfileResponse =
          await extUserActions.getCustomerProfileAsync(headers, id!);
        if (customerProfileResponse.isFailure) {
          showMessage(
            locales.ApiResponses.errorFetchingCustomer,
            'negative',
            previousPath
          );
          return;
        }

        isCustomerProfilePopulated.current = customerProfileResponse.result !== undefined;

        const diagnosisRecordResponse = await DiagnosisRecordActions.retrieveDiagnosisRecordsAsync(
          id,
          headers,
        );

        if (diagnosisRecordResponse.isFailure) {
          showMessage(
            locales.ApiResponses.errorFetchingDiagnosisRecords,
            'negative',
            previousPath
          );
          return;
        }

        setDiagnosisRecords(Array.isArray(diagnosisRecordResponse.result) ? diagnosisRecordResponse.result.map(mapRows) : []);

        const ethnicity = Enumerations.getEthnicity(intl.locale);
        const disabilities = Enumerations.getDisabilities(intl.locale);
        const genders = Enumerations.getGender(intl.locale);
        const pronouns = Enumerations.getPronouns(intl.locale);
        const employmentType = Enumerations.getEmploymentTypes(intl.locale);
        setNameTable([
          {
            groupName: 'id',
            rows: [
              {
                label: locales.tables.nameTable.lexxicId,
                value: userResponse.result!.id || '',
                showEmpty: true,
              },
            ],
          },
          {
            groupName: 'name',
            rows: [
              {
                label: locales.tables.nameTable.customerFirstName,
                value: userResponse.result!.name || '',
                showEmpty: true,
              },
              {
                label: locales.tables.nameTable.customerLastName,
                value: userResponse.result!.surname || '',
                showEmpty: true,
              },
            ],
          },
          {
            groupName: 'personalDetails',
            rows: [
              {
                label: locales.tables.nameTable.gender,
                value:
                  genders.find((x) => x.key === userResponse.result!.gender)
                    ?.value || '',
                showEmpty: true,
              },
              {
                label: locales.tables.nameTable.dateOfBirth,
                value:
                  DateHelpers.getLocalDateString(
                    userResponse.result!.birthDate,
                    intl,
                    'MMMM'
                  ) || '',
                showEmpty: true,
              },
            ],
          },
        ]);

        setPersonalDetailsTable([
          {
            groupName: 'personalDetails',
            rows: [
              {
                label: locales.tables.personalTable.jobTitle,
                value: userResponse.result!.jobTitle || '',
                showEmpty: true,
              },
              {
                label: locales.tables.personalTable.ethnicity,
                value:
                  ethnicity.find(
                    (e) => e.key === userResponse.result!.ethnicity
                  )?.value || '',
                showEmpty: true,
              },
              {
                label: locales.tables.personalTable.disabilities,
                value:
                  userResponse
                    .result!.disabilities.map(
                      (d) => disabilities.find((dis) => dis.key === d)?.value
                    )
                    .filter((v) => v)
                    .join(', ') || '',
                showEmpty: true,
              },
            ],
          },
        ]);

        setContactDetailsTable([
          {
            groupName: 'email',
            rows: [
              {
                label: locales.tables.contactTable.primaryEmailAddress,
                value: userResponse.result!.primaryEmail || '',
                showEmpty: true,
              },
              {
                label: locales.tables.contactTable.secondaryEmailAddress,
                value: userResponse.result!.secondaryEmail || '',
                showEmpty: false,
              },
            ],
          },
          {
            groupName: 'phone',
            rows: [
              {
                label: locales.tables.contactTable.primaryPhoneNumber,
                value: userResponse.result!.primaryTelephoneNumber || '',
                showEmpty: true,
              },
              {
                label: locales.tables.contactTable.secondaryPhoneNumber,
                value: userResponse.result!.secondaryTelephoneNumber || '',
                showEmpty: false,
              },
              {
                label: locales.tables.contactTable.address,
                value:
                  [
                    userResponse.result!.homeAddress?.line1,
                    userResponse.result!.homeAddress?.line2,
                    userResponse.result!.homeAddress?.line3,
                    userResponse.result!.homeAddress?.country,
                    userResponse.result!.homeAddress?.postalCode,
                  ]
                    .filter((line) => line)
                    .join(', ') || '',
                showEmpty: true,
              },
            ],
          },
        ]);

        if (!isCustomerProfilePopulated.current) {
          return;
        }

        setProfileNameTable([
          {
            groupName: 'profile',
            rows: [
              {
                label: locales.tables.profileNameTable.name,
                value: customerProfileResponse.result!.preferredName || '',
                showEmpty: true,
              },
              {
                label: locales.tables.profileNameTable.pronouns,
                value:
                  pronouns.find(
                    (p) => p.key === customerProfileResponse.result!.pronouns
                  )?.value || '',
                showEmpty: true,
              },
            ],
          },
        ]);

        setEmploymentTable([
          {
            groupName: 'employment',
            rows: [
              {
                label: locales.tables.employmentTable.employmentType,
                value:
                  employmentType.find(
                    (e) =>
                      e.key === customerProfileResponse.result!.employmentType
                  )?.value || '',
                showEmpty: false,
              },
              {
                label: locales.tables.employmentTable.lengthOfService,
                value: customerProfileResponse.result!.lengthOfEmployment || '',
                showEmpty: false,
              },
              {
                label: locales.tables.employmentTable.rolesAndResponsibilities,
                value:
                  customerProfileResponse.result!.roleAndResponsibilities || '',
                showEmpty: true,
              },
              {
                label: locales.tables.employmentTable.employmentHistory,
                value: customerProfileResponse.result!.employmentHistory || '',
                showEmpty: true,
              },
            ],
          },
        ]);

        if (!customerProfileResponse.result!.customerInEducation) {
          return;
        }

        setEducationTable([
          {
            groupName: 'education',
            rows: [
              {
                label: locales.tables.educationTable.instName,
                value:
                  customerProfileResponse.result!.customerInEducation
                    ?.universityCollegeName || '',
                showEmpty: false,
              },
              {
                label: locales.tables.educationTable.courseName,
                value:
                  customerProfileResponse.result!.customerInEducation
                    ?.courseName || '',
                showEmpty: false,
              },
              {
                label: locales.tables.educationTable.lengthOfService,
                value:
                  customerProfileResponse.result!.customerInEducation
                    ?.lengthOfCourse || '',
                showEmpty: false,
              },
              {
                label: locales.tables.educationTable.yearOfStudy,
                value:
                  DateHelpers.getLocalDateString(
                    customerProfileResponse.result!.customerInEducation
                      ?.yearOfStudy!,
                    intl,
                    'MMMM'
                  ) || '',
                showEmpty: false,
              },
            ],
          },
        ]);
      } catch (error) {
        console.error(error);
        showMessage(
          locales.ApiResponses.errorFetchingUser,
          'negative',
          previousPath
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchData().then(() => {
      setIsLoading(false);
    });
  }, []);

  const renderCustomerProfile = () => {
    return !isCustomerProfilePopulated.current ? (
      <>
        <div className="pageTile">
          <h1 className="Font-Heading">{locales.title2}</h1>
        </div>
        <div className="Empty-Message Text-Strong">{locales.noCustomerProfile}</div>
      </>
    ) : (
      <>

        <KeyValueTable
          tableName={locales.tables.profileNameTable.name}
          id="profileNameTable"
          groups={profileNameTable}
        />
        <div className="View-Splitter"></div>
        <KeyValueTable
          tableName={locales.tables.employmentTable.name}
          id="employmentTable"
          groups={employmentTable}
        />
        {educationTable.length > 0 && (
          <>
            <div className="View-Splitter"></div>
            <KeyValueTable
              tableName={locales.tables.educationTable.name}
              id="educationTable"
              groups={educationTable}
            />
          </>
        )}
      </>
    );
  };

  return isLoading ? (
    <PageLoader alt={locales.loading} />
  ) : (
    <div className='Main-Form-Layout'>
      <PillControl
        pages={[
          {
            name: pageStates[0].key,
            enabled: true,
            mode: pageStates[0].value as ControlState,
            content: (
              <>
                <div className="pageTile">
                  <h1 className="Font-Heading">{locales.title1}</h1>
                </div>
                <KeyValueTable
                  tableName={locales.tables.nameTable.name}
                  id="tableName"
                  groups={nameTable}
                />
                <div className="View-Splitter"></div>
                <KeyValueTable
                  tableName={locales.tables.personalTable.name}
                  id="personalTable"
                  groups={personalDetailsTable}
                />
                <div className="View-Splitter"></div>
                <KeyValueTable
                  tableName={locales.tables.contactTable.name}
                  id="personalTable"
                  groups={contactDetailsTable}
                />
              </>
            ),
            showAsOrdered: false,
            orderNo: 1,
          },
          {
            name: pageStates[1].key,
            enabled: true,
            mode: pageStates[1].value as ControlState,
            content: renderCustomerProfile(),
            showAsOrdered: false,
            orderNo: 2,
          },
          {
            name: pageStates[2].key,
            enabled: true,
            mode: pageStates[2].value as ControlState,
            content: (
              <DiagnosisRecordList
                rows={diagnosisRecords}
                cols={[
                  {
                    name: locales.diagnosisRecordListLabels.columns.diagnosedCondition,
                    style: 'sortable',
                    id: 'diagnosedCondition',
                    field: 'diagnosedCondition',
                    searchType: 'string',
                  },
                  {
                    name: locales.diagnosisRecordListLabels.columns.diagnosisDate,
                    style: 'sortable',
                    id: 'diagnosisDate',
                    field: 'diagnosisDate',
                    searchType: 'date',
                  },
                  {
                    name: locales.diagnosisRecordListLabels.columns.diagnosisSource,
                    style: 'sortable',
                    id: 'diagnosisSource',
                    field: 'diagnosisSource',
                    searchType: 'string',
                  },
                ] as ColDetails[]}
                labels={locales.diagnosisRecordListLabels}
                pagingDetails={{
                  currentPageSize: userSettings.startingPageSize,
                  pageSizes: userSettings.pageSizes,
                }}
                readOnly={true}
                isLoading={isLoading}
                enumerations={{
                  relatedConditions: diagnosedConditions,
                  diagnosisSources: diagnosisSources.filter((source) => source.key !== 0),
                }}
              />
            ),
            showAsOrdered: false,
            orderNo: 3,
          },
        ]}
        nextLabel={locales.navigationLabels.next}
        backLabel={locales.navigationLabels.back}
      />
    </div>
  );
};

export default ExternalUserView;
